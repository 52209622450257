import {TcodeCityDTO} from '../dto/tcode-city-dto';

export class BaseTcodeCountryDTO {

	id:number;
	englishName:string;
	arabicName:string;
	extension:string;
	active:boolean;
	recCrtDate:string;
	recUpdtDate:string;
	recCrtUser:number;
	recUpdtUser:number;

	tcodeCityList:Array<TcodeCityDTO> = new Array<TcodeCityDTO>();
}