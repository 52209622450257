import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {Api} from './api.service';
import {RestReturnCustomerDTO} from '../dto/rest-return-customer-dto';
import {CustomerDTO} from '../dto/customer-dto';
import {RestaurantDTO} from '../dto/restaurant-dto';

@Injectable()
export class BaseRestReturnCustomerService {

	constructor(public api:Api) {

	}

	getRestReturnCustomerById(id:number) : Observable<RestReturnCustomerDTO> {

		return this.api.post<RestReturnCustomerDTO>("RestReturnCustomer/getRestReturnCustomerById", id, false );
	}

	getRestReturnCustomer(restReturnCustomerDTO:RestReturnCustomerDTO) : Observable<RestReturnCustomerDTO> {

		return this.api.post<RestReturnCustomerDTO>("RestReturnCustomer/getRestReturnCustomer", restReturnCustomerDTO, false );
	}

	getRestReturnCustomersByCustomerId(customerDTO:CustomerDTO) : Observable<CustomerDTO> {

		return this.api.post<CustomerDTO>("RestReturnCustomer/getRestReturnCustomersByCustomerId", customerDTO, false );
	}

	getRestReturnCustomerListByCustomerId(customerId:number) : Observable<Array<RestReturnCustomerDTO>> {

		return this.api.post<Array<RestReturnCustomerDTO>>("RestReturnCustomer/getRestReturnCustomerListByCustomerId", customerId, false );
	}

	getRestReturnCustomerMapByCustomerId(customerId:number) : Observable<Map<number, RestReturnCustomerDTO>>  {

		return this.api.post<Map<number, RestReturnCustomerDTO>>("RestReturnCustomer/getRestReturnCustomerMapByCustomerId", customerId , false );
	}

	getRestReturnCustomersByRestaurantId(restaurantDTO:RestaurantDTO) : Observable<RestaurantDTO> {

		return this.api.post<RestaurantDTO>("RestReturnCustomer/getRestReturnCustomersByRestaurantId", restaurantDTO , false );
	}

	getRestReturnCustomerListByRestaurantId(restaurantId:number) : Observable<Array<RestReturnCustomerDTO>> {

		return this.api.post<Array<RestReturnCustomerDTO>>("RestReturnCustomer/getRestReturnCustomerListByRestaurantId", restaurantId , false );
	}

	getRestReturnCustomerMapByRestaurantId(restaurantId:number) : Observable<Map<number, RestReturnCustomerDTO>>  {

		return this.api.post<Map<number, RestReturnCustomerDTO>>("RestReturnCustomer/getRestReturnCustomerMapByRestaurantId", restaurantId , false );
	}

	getRestReturnCustomerList() : Observable<RestReturnCustomerDTO[]> {

		return this.api.get<RestReturnCustomerDTO[]>("RestReturnCustomer/getRestReturnCustomerList" , false );
	}

	getRestReturnCustomerMap() : Observable<Map<number, RestReturnCustomerDTO>> {

		return this.api.get<Map<number, RestReturnCustomerDTO>>("RestReturnCustomer/getRestReturnCustomerMap" , false );
	}

	searchRestReturnCustomerList(restReturnCustomerDTO:RestReturnCustomerDTO) : Observable<Array<RestReturnCustomerDTO>> {

		return this.api.post<Array<RestReturnCustomerDTO>>("RestReturnCustomer/searchRestReturnCustomerList", restReturnCustomerDTO , false );
	}

	searchRestReturnCustomerMap(restReturnCustomerDTO:RestReturnCustomerDTO) : Observable<Map<number, RestReturnCustomerDTO>> {

		return this.api.post<Map<number, RestReturnCustomerDTO>>("RestReturnCustomer/searchRestReturnCustomerMap", restReturnCustomerDTO , false );
	}

	addRestReturnCustomer(restReturnCustomerDTO:RestReturnCustomerDTO) : Observable<RestReturnCustomerDTO> {

		return this.api.post<RestReturnCustomerDTO>("RestReturnCustomer/addRestReturnCustomer", restReturnCustomerDTO , false );
	}

	addRestReturnCustomerList(restReturnCustomerList:Array<RestReturnCustomerDTO>) : Observable<Array<RestReturnCustomerDTO>> {

		return this.api.post<Array<RestReturnCustomerDTO>>("RestReturnCustomer/addRestReturnCustomerList", restReturnCustomerList , false );
	}

	updateRestReturnCustomer(restReturnCustomerDTO:RestReturnCustomerDTO) : Observable<RestReturnCustomerDTO> {

		return this.api.post<RestReturnCustomerDTO>("RestReturnCustomer/updateRestReturnCustomer", restReturnCustomerDTO , false );
	}

	updateRestReturnCustomerList(restReturnCustomerList:Array<RestReturnCustomerDTO>) : Observable<Array<RestReturnCustomerDTO>> {

		return this.api.post<Array<RestReturnCustomerDTO>>("RestReturnCustomer/updateRestReturnCustomerList", restReturnCustomerList , false );
	}

	saveRestReturnCustomer(restReturnCustomerDTO:RestReturnCustomerDTO) : Observable<RestReturnCustomerDTO> {

		return this.api.post<RestReturnCustomerDTO>("RestReturnCustomer/saveRestReturnCustomer", restReturnCustomerDTO , false );
	}

	saveRestReturnCustomerList(restReturnCustomerList:Array<RestReturnCustomerDTO>) : Observable<Array<RestReturnCustomerDTO>> {

		return this.api.post<Array<RestReturnCustomerDTO>>("RestReturnCustomer/saveRestReturnCustomerList", restReturnCustomerList , false );
	}

	activateRestReturnCustomerById(id:number) : Observable<number>{

		return this.api.post<number>("RestReturnCustomer/activateRestReturnCustomerById", id , false );
	}

	activateRestReturnCustomer(restReturnCustomerDTO:RestReturnCustomerDTO) : Observable<RestReturnCustomerDTO> {

		return this.api.post<RestReturnCustomerDTO>("RestReturnCustomer/activateRestReturnCustomer", restReturnCustomerDTO , false );
	}

	activateRestReturnCustomerList(restReturnCustomerList:Array<RestReturnCustomerDTO>) : Observable<Array<RestReturnCustomerDTO>> {

		return this.api.post<Array<RestReturnCustomerDTO>>("RestReturnCustomer/activateRestReturnCustomerList", restReturnCustomerList , false );
	}

	deactivateRestReturnCustomerById(id:number) : Observable<number> {

		return this.api.post<number>("RestReturnCustomer/deactivateRestReturnCustomerById", id , false );
	}

	deactivateRestReturnCustomer(restReturnCustomerDTO:RestReturnCustomerDTO) : Observable<RestReturnCustomerDTO> {

		return this.api.post<RestReturnCustomerDTO>("RestReturnCustomer/deactivateRestReturnCustomer", restReturnCustomerDTO , false );
	}

	deactivateRestReturnCustomerList(restReturnCustomerList:Array<RestReturnCustomerDTO>) : Observable<Array<RestReturnCustomerDTO>> {

		return this.api.post<Array<RestReturnCustomerDTO>>("RestReturnCustomer/deactivateRestReturnCustomerList", restReturnCustomerList , false );
	}
}