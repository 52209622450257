import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {Api} from './api.service';
import {TcodeSupplierTypeDTO} from '../dto/tcode-supplier-type-dto';

@Injectable()
export class BaseTcodeSupplierTypeService {

	constructor(public api:Api) {

	}

	getTcodeSupplierTypeById(id:number) : Observable<TcodeSupplierTypeDTO> {

		return this.api.post<TcodeSupplierTypeDTO>("TcodeSupplierType/getTcodeSupplierTypeById", id, false );
	}

	getTcodeSupplierType(tcodeSupplierTypeDTO:TcodeSupplierTypeDTO) : Observable<TcodeSupplierTypeDTO> {

		return this.api.post<TcodeSupplierTypeDTO>("TcodeSupplierType/getTcodeSupplierType", tcodeSupplierTypeDTO, false );
	}

	getTcodeSupplierTypeList() : Observable<TcodeSupplierTypeDTO[]> {

		return this.api.get<TcodeSupplierTypeDTO[]>("TcodeSupplierType/getTcodeSupplierTypeList", false );
	}

	getTcodeSupplierTypeMap() : Observable<Map<number, TcodeSupplierTypeDTO>> {

		return this.api.get<Map<number, TcodeSupplierTypeDTO>>("TcodeSupplierType/getTcodeSupplierTypeMap", false );
	}

	searchTcodeSupplierTypeList(tcodeSupplierTypeDTO:TcodeSupplierTypeDTO) : Observable<Array<TcodeSupplierTypeDTO>> {

		return this.api.post<Array<TcodeSupplierTypeDTO>>("TcodeSupplierType/searchTcodeSupplierTypeList", tcodeSupplierTypeDTO, false );
	}

	searchTcodeSupplierTypeMap(tcodeSupplierTypeDTO:TcodeSupplierTypeDTO) : Observable<Map<number, TcodeSupplierTypeDTO>> {

		return this.api.post<Map<number, TcodeSupplierTypeDTO>>("TcodeSupplierType/searchTcodeSupplierTypeMap", tcodeSupplierTypeDTO, false );
	}

	addTcodeSupplierType(tcodeSupplierTypeDTO:TcodeSupplierTypeDTO) : Observable<TcodeSupplierTypeDTO> {

		return this.api.post<TcodeSupplierTypeDTO>("TcodeSupplierType/addTcodeSupplierType", tcodeSupplierTypeDTO, false );
	}

	addTcodeSupplierTypeList(tcodeSupplierTypeList:Array<TcodeSupplierTypeDTO>) : Observable<Array<TcodeSupplierTypeDTO>> {

		return this.api.post<Array<TcodeSupplierTypeDTO>>("TcodeSupplierType/addTcodeSupplierTypeList", tcodeSupplierTypeList, false );
	}

	updateTcodeSupplierType(tcodeSupplierTypeDTO:TcodeSupplierTypeDTO) : Observable<TcodeSupplierTypeDTO> {

		return this.api.post<TcodeSupplierTypeDTO>("TcodeSupplierType/updateTcodeSupplierType", tcodeSupplierTypeDTO, false );
	}

	updateTcodeSupplierTypeList(tcodeSupplierTypeList:Array<TcodeSupplierTypeDTO>) : Observable<Array<TcodeSupplierTypeDTO>> {

		return this.api.post<Array<TcodeSupplierTypeDTO>>("TcodeSupplierType/updateTcodeSupplierTypeList", tcodeSupplierTypeList, false );
	}

	saveTcodeSupplierType(tcodeSupplierTypeDTO:TcodeSupplierTypeDTO) : Observable<TcodeSupplierTypeDTO> {

		return this.api.post<TcodeSupplierTypeDTO>("TcodeSupplierType/saveTcodeSupplierType", tcodeSupplierTypeDTO, false );
	}

	saveTcodeSupplierTypeList(tcodeSupplierTypeList:Array<TcodeSupplierTypeDTO>) : Observable<Array<TcodeSupplierTypeDTO>> {

		return this.api.post<Array<TcodeSupplierTypeDTO>>("TcodeSupplierType/saveTcodeSupplierTypeList", tcodeSupplierTypeList, false );
	}

	activateTcodeSupplierTypeById(id:number) : Observable<number>{

		return this.api.post<number>("TcodeSupplierType/activateTcodeSupplierTypeById", id, false );
	}

	activateTcodeSupplierType(tcodeSupplierTypeDTO:TcodeSupplierTypeDTO) : Observable<TcodeSupplierTypeDTO> {

		return this.api.post<TcodeSupplierTypeDTO>("TcodeSupplierType/activateTcodeSupplierType", tcodeSupplierTypeDTO, false );
	}

	activateTcodeSupplierTypeList(tcodeSupplierTypeList:Array<TcodeSupplierTypeDTO>) : Observable<Array<TcodeSupplierTypeDTO>> {

		return this.api.post<Array<TcodeSupplierTypeDTO>>("TcodeSupplierType/activateTcodeSupplierTypeList", tcodeSupplierTypeList, false );
	}

	deactivateTcodeSupplierTypeById(id:number) : Observable<number> {

		return this.api.post<number>("TcodeSupplierType/deactivateTcodeSupplierTypeById", id, false );
	}

	deactivateTcodeSupplierType(tcodeSupplierTypeDTO:TcodeSupplierTypeDTO) : Observable<TcodeSupplierTypeDTO> {

		return this.api.post<TcodeSupplierTypeDTO>("TcodeSupplierType/deactivateTcodeSupplierType", tcodeSupplierTypeDTO, false );
	}

	deactivateTcodeSupplierTypeList(tcodeSupplierTypeList:Array<TcodeSupplierTypeDTO>) : Observable<Array<TcodeSupplierTypeDTO>> {

		return this.api.post<Array<TcodeSupplierTypeDTO>>("TcodeSupplierType/deactivateTcodeSupplierTypeList", tcodeSupplierTypeList, false );
	}
}