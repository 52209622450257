import {RestaurantDTO} from '../dto/restaurant-dto';
import {CustomerOfferDTO} from '../dto/customer-offer-dto';

export class BaseOfferDTO {

	id:number;
	restaurantDTO:RestaurantDTO = new RestaurantDTO();
	englishTitle:string;
	arabicTitle:string;
	englishDescription:string;
	arabicDescription:string;
	englishTerms:string;
	arabicTerms:string;
	startDate:string;
	endDate:string;
	maxOfferConsume:number;
	targetedAge:number;
	targetedGender:string;
	targetedDistance:number;
	active:boolean;
	recCrtDate:string;
	recUpdtDate:string;
	recCrtUser:number;
	recUpdtUser:number;
	englishTermsTiming:string;
	arabicTermsTiming:string;
	noOfferRecommendation:boolean;
	
	customerOfferList:Array<CustomerOfferDTO> = new Array<CustomerOfferDTO>();
}