import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {Api} from './api.service';
import {RestaurantDesignDTO} from '../dto/restaurant-design-dto';
import {TcodeRestDesignDTO} from '../dto/tcode-rest-design-dto';
import {RestaurantDTO} from '../dto/restaurant-dto';

@Injectable()
export class BaseRestaurantDesignService {

	constructor(public api:Api) {

	}

	getRestaurantDesignById(id:number) : Observable<RestaurantDesignDTO> {

		return this.api.post<RestaurantDesignDTO>("RestaurantDesign/getRestaurantDesignById", id, false );
	}

	getRestaurantDesign(restaurantDesignDTO:RestaurantDesignDTO) : Observable<RestaurantDesignDTO> {

		return this.api.post<RestaurantDesignDTO>("RestaurantDesign/getRestaurantDesign", restaurantDesignDTO, false );
	}

	getRestaurantDesignsByDesignId(tcodeRestDesignDTO:TcodeRestDesignDTO) : Observable<TcodeRestDesignDTO> {

		return this.api.post<TcodeRestDesignDTO>("RestaurantDesign/getRestaurantDesignsByDesignId", tcodeRestDesignDTO, false );
	}

	getRestaurantDesignListByDesignId(designId:number) : Observable<Array<RestaurantDesignDTO>> {

		return this.api.post<Array<RestaurantDesignDTO>>("RestaurantDesign/getRestaurantDesignListByDesignId", designId, false );
	}

	getRestaurantDesignMapByDesignId(designId:number) : Observable<Map<number, RestaurantDesignDTO>>  {

		return this.api.post<Map<number, RestaurantDesignDTO>>("RestaurantDesign/getRestaurantDesignMapByDesignId", designId, false );
	}

	getRestaurantDesignsByRestaurantId(restaurantDTO:RestaurantDTO) : Observable<RestaurantDTO> {

		return this.api.post<RestaurantDTO>("RestaurantDesign/getRestaurantDesignsByRestaurantId", restaurantDTO, false );
	}

	getRestaurantDesignListByRestaurantId(restaurantId:number) : Observable<Array<RestaurantDesignDTO>> {

		return this.api.post<Array<RestaurantDesignDTO>>("RestaurantDesign/getRestaurantDesignListByRestaurantId", restaurantId, false );
	}

	getRestaurantDesignMapByRestaurantId(restaurantId:number) : Observable<Map<number, RestaurantDesignDTO>>  {

		return this.api.post<Map<number, RestaurantDesignDTO>>("RestaurantDesign/getRestaurantDesignMapByRestaurantId", restaurantId, false );
	}

	getRestaurantDesignList() : Observable<RestaurantDesignDTO[]> {

		return this.api.get<RestaurantDesignDTO[]>("RestaurantDesign/getRestaurantDesignList", false );
	}

	getRestaurantDesignMap() : Observable<Map<number, RestaurantDesignDTO>> {

		return this.api.get<Map<number, RestaurantDesignDTO>>("RestaurantDesign/getRestaurantDesignMap", false );
	}

	searchRestaurantDesignList(restaurantDesignDTO:RestaurantDesignDTO) : Observable<Array<RestaurantDesignDTO>> {

		return this.api.post<Array<RestaurantDesignDTO>>("RestaurantDesign/searchRestaurantDesignList", restaurantDesignDTO, false );
	}

	searchRestaurantDesignMap(restaurantDesignDTO:RestaurantDesignDTO) : Observable<Map<number, RestaurantDesignDTO>> {

		return this.api.post<Map<number, RestaurantDesignDTO>>("RestaurantDesign/searchRestaurantDesignMap", restaurantDesignDTO, false );
	}

	addRestaurantDesign(restaurantDesignDTO:RestaurantDesignDTO) : Observable<RestaurantDesignDTO> {

		return this.api.post<RestaurantDesignDTO>("RestaurantDesign/addRestaurantDesign", restaurantDesignDTO, false );
	}

	addRestaurantDesignList(restaurantDesignList:Array<RestaurantDesignDTO>) : Observable<Array<RestaurantDesignDTO>> {

		return this.api.post<Array<RestaurantDesignDTO>>("RestaurantDesign/addRestaurantDesignList", restaurantDesignList, false );
	}

	updateRestaurantDesign(restaurantDesignDTO:RestaurantDesignDTO) : Observable<RestaurantDesignDTO> {

		return this.api.post<RestaurantDesignDTO>("RestaurantDesign/updateRestaurantDesign", restaurantDesignDTO, false );
	}

	updateRestaurantDesignList(restaurantDesignList:Array<RestaurantDesignDTO>) : Observable<Array<RestaurantDesignDTO>> {

		return this.api.post<Array<RestaurantDesignDTO>>("RestaurantDesign/updateRestaurantDesignList", restaurantDesignList, false );
	}

	saveRestaurantDesign(restaurantDesignDTO:RestaurantDesignDTO) : Observable<RestaurantDesignDTO> {

		return this.api.post<RestaurantDesignDTO>("RestaurantDesign/saveRestaurantDesign", restaurantDesignDTO, false );
	}

	saveRestaurantDesignList(restaurantDesignList:Array<RestaurantDesignDTO>) : Observable<Array<RestaurantDesignDTO>> {

		return this.api.post<Array<RestaurantDesignDTO>>("RestaurantDesign/saveRestaurantDesignList", restaurantDesignList, false );
	}

	activateRestaurantDesignById(id:number) : Observable<number>{

		return this.api.post<number>("RestaurantDesign/activateRestaurantDesignById", id, false );
	}

	activateRestaurantDesign(restaurantDesignDTO:RestaurantDesignDTO) : Observable<RestaurantDesignDTO> {

		return this.api.post<RestaurantDesignDTO>("RestaurantDesign/activateRestaurantDesign", restaurantDesignDTO, false );
	}

	activateRestaurantDesignList(restaurantDesignList:Array<RestaurantDesignDTO>) : Observable<Array<RestaurantDesignDTO>> {

		return this.api.post<Array<RestaurantDesignDTO>>("RestaurantDesign/activateRestaurantDesignList", restaurantDesignList, false );
	}

	deactivateRestaurantDesignById(id:number) : Observable<number> {

		return this.api.post<number>("RestaurantDesign/deactivateRestaurantDesignById", id, false );
	}

	deactivateRestaurantDesign(restaurantDesignDTO:RestaurantDesignDTO) : Observable<RestaurantDesignDTO> {

		return this.api.post<RestaurantDesignDTO>("RestaurantDesign/deactivateRestaurantDesign", restaurantDesignDTO, false );
	}

	deactivateRestaurantDesignList(restaurantDesignList:Array<RestaurantDesignDTO>) : Observable<Array<RestaurantDesignDTO>> {

		return this.api.post<Array<RestaurantDesignDTO>>("RestaurantDesign/deactivateRestaurantDesignList", restaurantDesignList, false );
	}
}