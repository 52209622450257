<ion-app dir="{{textDir}}">
  <!-- when=false means that the split pane will never expand automatically -->
  <!-- For more info check https://ionicframework.com/docs/api/split-pane -->
  <ion-split-pane contentId="menu-content" when="false">
    <ion-menu [side]="language=='ar' ? 'end' : 'start' " contentId="menu-content" class="app-sidemenu">
      <ion-header>
        <ion-toolbar>
          <ion-row class="user-details-wrapper">
            <ion-col size="4">
              <app-aspect-ratio [ratio]="{w: 1, h: 1}">
                <app-image-shell  *ngIf="!isLoggedIn || customerDTO==null ||(isLoggedIn && customerDTO.photo ==null)" class="user-avatar" animation="spinner" [src]="'./assets/images/default_avatar.png'"></app-image-shell>
                <app-image-shell  *ngIf="isLoggedIn && customerDTO!=null" class="user-avatar" animation="spinner" [src]="customerDTO.photo"></app-image-shell>

              </app-aspect-ratio>
            </ion-col>
          
          </ion-row>
          <ion-row class="user-details-wrapper">
              <h3 class="body-copy-small" >{{customerDTO!=null && customerDTO.name!='' ? customerDTO.name : "GUEST"|translate}}</h3>
              <!-- <h5 class="user-handle">@IonicThemes</h5> -->
          </ion-row>
        </ion-toolbar>
      </ion-header>
      <ion-content [class.arabic]="language == 'ar'" >
        <ion-list *ngIf="isLoggedIn">
          <ion-menu-toggle autoHide="false" *ngFor="let page of fadosLoggedInPages; let i = index">
            <ion-item [routerLink]="page.url">
              <ion-icon slot="start" [name]="page.ionicIcon? page.ionicIcon: ''" [src]="page.customIcon? page.customIcon: ''"></ion-icon>

              <ion-label class="side-menu-label-text"> <!--class="headline-sub-header  label-space-padding-20" -->
                {{page.title | translate}}
              </ion-label>
            </ion-item>
          </ion-menu-toggle>
        </ion-list>

        <ion-list *ngIf="!isLoggedIn">
          <ion-menu-toggle autoHide="false" *ngFor="let page of fadosGuestPages; let i = index">
            <ion-item [routerLink]="page.url">
              <ion-icon slot="start" [name]="page.ionicIcon? page.ionicIcon: ''" [src]="page.customIcon? page.customIcon: ''"></ion-icon>
              <ion-label class="side-menu-label-text"> <!--class="headline-sub-header  label-space-padding-20" -->
                {{page.title  | translate}}
              </ion-label>
            </ion-item>
          </ion-menu-toggle>
        </ion-list>
      </ion-content>
    </ion-menu>
    <!-- Main app content get's rendered in this router-outlet -->
    <ion-router-outlet id="menu-content"></ion-router-outlet>
  </ion-split-pane>
</ion-app>
