import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {Api} from './api.service';
import {RestaurantDTO} from '../dto/restaurant-dto';

@Injectable()
export class BaseRestaurantService {

	constructor(public api:Api) {

	}

	getRestaurantById(id:number) : Observable<RestaurantDTO> {

		return this.api.post<RestaurantDTO>("Restaurant/getRestaurantById", id, false );
	}

	getRestaurant(restaurantDTO:RestaurantDTO) : Observable<RestaurantDTO> {

		return this.api.post<RestaurantDTO>("Restaurant/getRestaurant", restaurantDTO, false );
	}

	getRestaurantList() : Observable<RestaurantDTO[]> {

		return this.api.get<RestaurantDTO[]>("Restaurant/getRestaurantList", false );
	}

	getRestaurantMap() : Observable<Map<number, RestaurantDTO>> {

		return this.api.get<Map<number, RestaurantDTO>>("Restaurant/getRestaurantMap", false );
	}

	searchRestaurantList(restaurantDTO:RestaurantDTO) : Observable<Array<RestaurantDTO>> {

		return this.api.post<Array<RestaurantDTO>>("Restaurant/searchRestaurantList", restaurantDTO, false );
	}

	searchRestaurantMap(restaurantDTO:RestaurantDTO) : Observable<Map<number, RestaurantDTO>> {

		return this.api.post<Map<number, RestaurantDTO>>("Restaurant/searchRestaurantMap", restaurantDTO, false );
	}

	addRestaurant(restaurantDTO:RestaurantDTO) : Observable<RestaurantDTO> {

		return this.api.post<RestaurantDTO>("Restaurant/addRestaurant", restaurantDTO, false );
	}

	addRestaurantList(restaurantList:Array<RestaurantDTO>) : Observable<Array<RestaurantDTO>> {

		return this.api.post<Array<RestaurantDTO>>("Restaurant/addRestaurantList", restaurantList, false );
	}

	updateRestaurant(restaurantDTO:RestaurantDTO) : Observable<RestaurantDTO> {

		return this.api.post<RestaurantDTO>("Restaurant/updateRestaurant", restaurantDTO, false );
	}

	updateRestaurantList(restaurantList:Array<RestaurantDTO>) : Observable<Array<RestaurantDTO>> {

		return this.api.post<Array<RestaurantDTO>>("Restaurant/updateRestaurantList", restaurantList, false );
	}

	saveRestaurant(restaurantDTO:RestaurantDTO) : Observable<RestaurantDTO> {

		return this.api.post<RestaurantDTO>("Restaurant/saveRestaurant", restaurantDTO, false );
	}

	saveRestaurantList(restaurantList:Array<RestaurantDTO>) : Observable<Array<RestaurantDTO>> {

		return this.api.post<Array<RestaurantDTO>>("Restaurant/saveRestaurantList", restaurantList, false );
	}

	activateRestaurantById(id:number) : Observable<number>{

		return this.api.post<number>("Restaurant/activateRestaurantById", id, false );
	}

	activateRestaurant(restaurantDTO:RestaurantDTO) : Observable<RestaurantDTO> {

		return this.api.post<RestaurantDTO>("Restaurant/activateRestaurant", restaurantDTO, false );
	}

	activateRestaurantList(restaurantList:Array<RestaurantDTO>) : Observable<Array<RestaurantDTO>> {

		return this.api.post<Array<RestaurantDTO>>("Restaurant/activateRestaurantList", restaurantList, false );
	}

	deactivateRestaurantById(id:number) : Observable<number> {

		return this.api.post<number>("Restaurant/deactivateRestaurantById", id, false );
	}

	deactivateRestaurant(restaurantDTO:RestaurantDTO) : Observable<RestaurantDTO> {

		return this.api.post<RestaurantDTO>("Restaurant/deactivateRestaurant", restaurantDTO, false );
	}

	deactivateRestaurantList(restaurantList:Array<RestaurantDTO>) : Observable<Array<RestaurantDTO>> {

		return this.api.post<Array<RestaurantDTO>>("Restaurant/deactivateRestaurantList", restaurantList, false );
	}
}