import {RestaurantDTO} from '../dto/restaurant-dto';
import {TcodeCuisineDTO} from '../dto/tcode-cuisine-dto';

export class BaseRestaurantCuisinesDTO {

	id:number;
	restaurantDTO:RestaurantDTO = new RestaurantDTO();
	cuisineDTO:TcodeCuisineDTO = new TcodeCuisineDTO();
	active:boolean;
	recCrtDate:string;
	recUpdtDate:string;
	recCrtUser:number;
	recUpdtUser:number;
}