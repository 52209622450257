import {TcodeRestDesignDTO} from '../dto/tcode-rest-design-dto';
import {RestaurantDTO} from '../dto/restaurant-dto';

export class BaseRestaurantDesignDTO {

	id:number;
	restaurantDTO:RestaurantDTO = new RestaurantDTO();
	designDTO:TcodeRestDesignDTO = new TcodeRestDesignDTO();
	active:boolean;
	recCrtDate:string;
	recUpdtDate:string;
	recCrtUser:number;
	recUpdtUser:number;
}