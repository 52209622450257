import {TcodeStatusDTO} from '../dto/tcode-status-dto';
import {TcodeSupplierTypeDTO} from '../dto/tcode-supplier-type-dto';
import {TcodeCityDTO} from '../dto/tcode-city-dto';
import {RestaurantRateDTO} from '../dto/restaurant-rate-dto';
import {RestaurantDTO} from '../dto/restaurant-dto';
import {UserDTO} from '../dto/user-dto';

export class BaseSupplierDTO {

	id:number;
	englishName:string;
	arabicName:string;
	statusDTO:TcodeStatusDTO = new TcodeStatusDTO();
	website:string;
	englishShortDescription:string;
	englishLongDescription:string;
	telephoneExt:string;
	telephoneNo:number;
	responsibleMobileExt:string;
	responsibleMobileNo:number;
	responsibleName:string;
	crNumber:number;
	crBranchNumber:number;
	rate:number;
	rateCount:number;
	logo:string;
	photo:string;
	commercialCertificate:string;
	englishOpeningHours:string;
	locationLatitude:string;
	locationLongitude:string;
	cityDTO:TcodeCityDTO = new TcodeCityDTO();
	addressTextEnglish:string;
	addressTextArabic:string;
	supplierTypeDTO:TcodeSupplierTypeDTO = new TcodeSupplierTypeDTO();
	active:boolean;
	recCrtDate:string;
	recUpdtDate:string;
	recCrtUser:number;
	recUpdtUser:number;
	arabicLongDescription:string;
	arabicShortDescription:string;
	arabicOpeningHours:string;

	restaurantRateList:Array<RestaurantRateDTO> = new Array<RestaurantRateDTO>();
	restaurantList:Array<RestaurantDTO> = new Array<RestaurantDTO>();
	userList:Array<UserDTO> = new Array<UserDTO>();
}