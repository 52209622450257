import { Component } from '@angular/core';
import { Plugins } from '@capacitor/core';
import { SeoService } from './utils/seo/seo.service';
const { SplashScreen} = Plugins;

import { TranslateService, LangChangeEvent } from '@ngx-translate/core';
import { HistoryHelperService } from './utils/history-helper.service';
import { OfflineStorage } from './services/storage.service';
import { LoginService } from './services/login.service';
import { CustomerDTO } from './dto/customer-dto';

import { NavController } from '@ionic/angular';





@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: [
    './side-menu/styles/side-menu.scss',
    './side-menu/styles/side-menu.shell.scss',
    './side-menu/styles/side-menu.responsive.scss'
  ]
})
export class AppComponent {

  isLoggedIn: boolean =false;
  customerDTO:CustomerDTO;
  
  fadosGuestPages = [
    {
      title: 'HOME_PAGE',
      url: '/home',
      customIcon: './assets/custom-icons/fados-icons/Home.svg'
    },
    {
      title: 'LOGIN_SIGNUP_TITLE',
      url: '/auth/login',
      customIcon: './assets/custom-icons/fados-icons/Login.svg'
    },
    {
      title: 'SETTINGS',
      url: '/settings',
      customIcon: './assets/custom-icons/fados-icons/Settings.svg'
    },
    // {
    //   title: 'SUGGESTIONS',
    //   url: '/suggestions',
    // },
    {
      title: 'ABOUT',
      url: '/about',
      customIcon: './assets/custom-icons/fados-icons/About.svg'
    }
  ];

  fadosLoggedInPages = [
    {
      title: 'HOME_PAGE',
      url: '/home',
      customIcon: './assets/custom-icons/fados-icons/Home.svg'
    },
    {
      title: 'PROFILE',
      url: '/profile',
      customIcon: './assets/custom-icons/fados-icons/Profile.svg'
    },
    {
      title: 'SETTINGS',
      url: '/settings',
      customIcon: './assets/custom-icons/fados-icons/Settings.svg'
    },
    {
      title: 'SUGGESTIONS',
      url: '/suggestions',
      customIcon: './assets/custom-icons/fados-icons/Suggestions.svg'
    },
    {
      title: 'ABOUT',
      url: '/about',
      customIcon: './assets/custom-icons/fados-icons/About.svg'
    }
  ];




//loggedIn:boolean = false;
  textDir = 'ltr';//ltr
  language = "en";//default
  public rootPage: any;


  // Inject HistoryHelperService in the app.components.ts so its available app-wide
  constructor(
    public translate: TranslateService,
    public historyHelper: HistoryHelperService,
    public storage:OfflineStorage,
    private seoService: SeoService,
    private data: LoginService ,
    public navCtrl: NavController
  ) {

    // App.addListener('appStateChange', (state) => {

    //   if (!state.isActive) {
    //     // The app has become inactive. We should check if we have some work left to do, and, if so,
    //     // execute a background task that will allow us to finish that work before the OS
    //     // suspends or terminates our app:
    
    //     let taskId = BackgroundTask.beforeExit(async () => {
    //       console.log("+++++++++++++++++++++++++ BeforeExit is called here so do your task here");
    //       // In this function We might finish an upload, let a network request
    //       // finish, persist some data, or perform some other task
    
    //       // Example of long task
    //       // var start = new Date().getTime();
    //       // for (var i = 0; i < 1e18; i++) {
    //       //   if ((new Date().getTime() - start) > 20000){
    //       //     break;
    //       //   }
    //       // }
    //       // Must call in order to end our task otherwise
    //       // we risk our app being terminated, and possibly
    //       // being labeled as impacting battery life
    //       BackgroundTask.finish({
    //         taskId
    //       });
    //       console.log("+++++++++++++++++++++++++ Finish is Done");
    
    //     });
    //   }else{
    //     //Stat is back to active so make process again here.
    //     console.log("+++++++++++++++++++++++++ Now the application is back to ACTIVE STATE so yes we can run some code here ");
    
    //   }
    // });

    this.data.loggedInStatus.subscribe( (isLoggedIn:boolean) => {
        this.isLoggedIn = isLoggedIn;
        if(isLoggedIn){
            this.storage.fetchCustomer().then((customerDTO:CustomerDTO) => {
                  this.customerDTO = customerDTO;
            });
        }else{
          this.customerDTO = new CustomerDTO();
          //this.customerDTO.name = "Guest";
        }   
    });

    // this.data.loggedInStatus.subscribe( (isLoggedIn:boolean) => {
    //   console.log("STEP 0 Subscribe to isLoggedIn: Is Logged In="+isLoggedIn);
    //   this.isLoggedIn = isLoggedIn;
    // });

    this.initializeApp();



    //When Customer updates his photo, need to refresh the side menu panel to get the updated photo
    this.data.updateCustomerPhoto.subscribe( (photo:string) => {
        
        this.customerDTO.photo = photo;
    });
    //this.setLoggedIn();
    //this.setLanguage();
    //this.checkUserSeenWalkthrough();
  }

  async initializeApp() {
    try {
      await this.readCustomerOffline();
      await SplashScreen.hide();
    } catch (err) {
     console.log('This is normal in a browser', err);
    }
  }

  async readCustomerOffline() {
     await this.storage.fetchCustomer().then((customerDTO:CustomerDTO) => {
          this.customerDTO = customerDTO;

          this.storage.isLoggedIn().then((isLoggedIn:boolean) => {
            console.log("STEP 1: Is he logged in from storage="+isLoggedIn);
            this.isLoggedIn = isLoggedIn;
             

  // this is to determine the text direction depending on the selected language
            // for the purpose of this example we determine that only arabic and hebrew are RTL.
            this.translate.onLangChange.subscribe((event: LangChangeEvent) => {
              this.textDir = (event.lang === 'ar' || event.lang === 'iw') ? 'rtl' : 'ltr';
              document.documentElement.dir =  (event.lang === 'ar' || event.lang === 'iw') ? 'rtl' : 'ltr';
            
              // console.log("app component -->Langauge has been changed , textDir = "+this.textDir);
              this.language= event.lang;
          });
          console.log("STEP 2: setting lang -- this is registered customer ");
            if(this.customerDTO!=null  && this.customerDTO.language!=null){
              
              // console.log("STEP 2 A : setting lang --regeistred Customer lang in local is= "+this.customerDTO.language);
              //Registered customer so get the langauge from his profile DTO
              this.translate.setDefaultLang(this.customerDTO.language);
              this.translate.use(this.customerDTO.language);
            }else{
              // the lang to use, if the lang isn't available, it will use the current loader to get them
              // console.log("STEP 2  B : setting lang -This is GUEST");
              this.storage.getGuestLangauge().then((guestLangauge:string) => {
                // console.log("STEP 2 B got the default langsetting lang -Guest lang have been read");
                  if(guestLangauge!=null && guestLangauge!=''){
                    // console.log("setting lang -Guest was set to ="+guestLangauge);

                    this.translate.setDefaultLang(guestLangauge);//Mark english as default langauge.
                    this.translate.use(guestLangauge);
                  }else{
                    // console.log("setting lang -Guest was set NOT SET So set to default english =");

                    this.translate.setDefaultLang('en');//Mark english as default langauge.
                    this.translate.use('en');
                  }
                    
                });
              
            }
            // console.log("STEP 3 : Last step display the user DTO")
            // console.log(this.customerDTO);

            
            // console.log("Step 4: Last navigate if all done");
            this.checkUserSeenWalkthrough();
              
              
          });

         
      });
  }

  /**
   * 
   * 
   * EVENTS is required to updated the side menu when logut from another component
   * 
   * 
   */
  // READ THIS TO CHANGE EVENTS ==> https://medium.com/wizpanda/dealing-with-breaking-change-in-ionic-5-db3ba711dfcd

  // events.subscribe("logout", () => {
  //   this.loggedIn = false;
  //   this.setLanguage("en");
  // });



// setLoggedIn(){
//     //this.customerDTO = new CustomerDTO();


    


//     // this.storage.fetchCustomer().then((customerDTO:CustomerDTO) => {
//     //     this.customerDTO = customerDTO;
//     //     console.log("We have read the customerDTO");
//     //     console.log(this.customerDTO);
//     // });

    

    
//   }

  checkUserSeenWalkthrough(){
    console.log("checkUserSeenWalkthrough");
      this.storage.isWalkthrougDisplayed().then((isWalkthrougDisplayed:boolean) => {
        if(isWalkthrougDisplayed!=null && isWalkthrougDisplayed){
            console.log("navigate home");
            // walkthouth was already displayed so navigate to home
            this.navCtrl.navigateRoot('home');
        }else{
            this.navCtrl.navigateRoot('walkthrough');
        }
      });
  }

}
