import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {Api} from './api.service';
import {CustomerWishlistDTO} from '../dto/customer-wishlist-dto';
import {CustomerDTO} from '../dto/customer-dto';

@Injectable()
export class BaseCustomerWishlistService {

	constructor(public api:Api) {

	}

	getCustomerWishlistById(id:number) : Observable<CustomerWishlistDTO> {

		return this.api.post<CustomerWishlistDTO>("CustomerWishlist/getCustomerWishlistById", id, false );
	}

	getCustomerWishlist(customerWishlistDTO:CustomerWishlistDTO) : Observable<CustomerWishlistDTO> {

		return this.api.post<CustomerWishlistDTO>("CustomerWishlist/getCustomerWishlist", customerWishlistDTO, false );
	}

	getCustomerWishlistsByCustomerId(customerDTO:CustomerDTO) : Observable<CustomerDTO> {

		return this.api.post<CustomerDTO>("CustomerWishlist/getCustomerWishlistsByCustomerId", customerDTO, false );
	}

	getCustomerWishlistListByCustomerId(customerId:number) : Observable<Array<CustomerWishlistDTO>> {

		return this.api.post<Array<CustomerWishlistDTO>>("CustomerWishlist/getCustomerWishlistListByCustomerId", customerId, false );
	}

	getCustomerWishlistMapByCustomerId(customerId:number) : Observable<Map<number, CustomerWishlistDTO>>  {

		return this.api.post<Map<number, CustomerWishlistDTO>>("CustomerWishlist/getCustomerWishlistMapByCustomerId", customerId, false );
	}

	getCustomerWishlistList() : Observable<CustomerWishlistDTO[]> {

		return this.api.get<CustomerWishlistDTO[]>("CustomerWishlist/getCustomerWishlistList", false );
	}

	getCustomerWishlistMap() : Observable<Map<number, CustomerWishlistDTO>> {

		return this.api.get<Map<number, CustomerWishlistDTO>>("CustomerWishlist/getCustomerWishlistMap", false );
	}

	searchCustomerWishlistList(customerWishlistDTO:CustomerWishlistDTO) : Observable<Array<CustomerWishlistDTO>> {

		return this.api.post<Array<CustomerWishlistDTO>>("CustomerWishlist/searchCustomerWishlistList", customerWishlistDTO, false );
	}

	searchCustomerWishlistMap(customerWishlistDTO:CustomerWishlistDTO) : Observable<Map<number, CustomerWishlistDTO>> {

		return this.api.post<Map<number, CustomerWishlistDTO>>("CustomerWishlist/searchCustomerWishlistMap", customerWishlistDTO, false );
	}

	addCustomerWishlist(customerWishlistDTO:CustomerWishlistDTO) : Observable<CustomerWishlistDTO> {

		return this.api.post<CustomerWishlistDTO>("CustomerWishlist/addCustomerWishlist", customerWishlistDTO, false );
	}

	addCustomerWishlistList(customerWishlistList:Array<CustomerWishlistDTO>) : Observable<Array<CustomerWishlistDTO>> {

		return this.api.post<Array<CustomerWishlistDTO>>("CustomerWishlist/addCustomerWishlistList", customerWishlistList, false );
	}

	updateCustomerWishlist(customerWishlistDTO:CustomerWishlistDTO) : Observable<CustomerWishlistDTO> {

		return this.api.post<CustomerWishlistDTO>("CustomerWishlist/updateCustomerWishlist", customerWishlistDTO, false );
	}

	updateCustomerWishlistList(customerWishlistList:Array<CustomerWishlistDTO>) : Observable<Array<CustomerWishlistDTO>> {

		return this.api.post<Array<CustomerWishlistDTO>>("CustomerWishlist/updateCustomerWishlistList", customerWishlistList, false );
	}

	saveCustomerWishlist(customerWishlistDTO:CustomerWishlistDTO) : Observable<CustomerWishlistDTO> {

		return this.api.post<CustomerWishlistDTO>("CustomerWishlist/saveCustomerWishlist", customerWishlistDTO, false );
	}

	saveCustomerWishlistList(customerWishlistList:Array<CustomerWishlistDTO>) : Observable<Array<CustomerWishlistDTO>> {

		return this.api.post<Array<CustomerWishlistDTO>>("CustomerWishlist/saveCustomerWishlistList", customerWishlistList, false );
	}

	activateCustomerWishlistById(id:number) : Observable<number>{

		return this.api.post<number>("CustomerWishlist/activateCustomerWishlistById", id, false );
	}

	activateCustomerWishlist(customerWishlistDTO:CustomerWishlistDTO) : Observable<CustomerWishlistDTO> {

		return this.api.post<CustomerWishlistDTO>("CustomerWishlist/activateCustomerWishlist", customerWishlistDTO, false );
	}

	activateCustomerWishlistList(customerWishlistList:Array<CustomerWishlistDTO>) : Observable<Array<CustomerWishlistDTO>> {

		return this.api.post<Array<CustomerWishlistDTO>>("CustomerWishlist/activateCustomerWishlistList", customerWishlistList, false );
	}

	deactivateCustomerWishlistById(id:number) : Observable<number> {

		return this.api.post<number>("CustomerWishlist/deactivateCustomerWishlistById", id, false );
	}

	deactivateCustomerWishlist(customerWishlistDTO:CustomerWishlistDTO) : Observable<CustomerWishlistDTO> {

		return this.api.post<CustomerWishlistDTO>("CustomerWishlist/deactivateCustomerWishlist", customerWishlistDTO, false );
	}

	deactivateCustomerWishlistList(customerWishlistList:Array<CustomerWishlistDTO>) : Observable<Array<CustomerWishlistDTO>> {

		return this.api.post<Array<CustomerWishlistDTO>>("CustomerWishlist/deactivateCustomerWishlistList", customerWishlistList, false );
	}
}