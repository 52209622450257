import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {Api} from '../base-service/api.service';
import { BaseCustomerService } from '../base-service/base-customer.service';
import { DealsListingModel } from '../deals/listing/deals-listing.model';
// import { CustomerOffersListModel } from '../dto/customer-offers-list-model';

import { CustomerDTO } from '../dto/customer-dto';
import { CustomerOfferDTO } from '../dto/customer-offer-dto';
import { GenerateCustomerOfferDTO } from '../dto/generate-customer-offer-dto';
import { TcodeOccupationDTO } from '../dto/tcode-occupation-dto';
import { UserDTO } from '../dto/user-dto';
import { ResolverHelper } from '../utils/resolver-helper';
import { OfflineStorage } from './storage.service';

@Injectable()
export class CustomerService extends BaseCustomerService{

	constructor(public api:Api , private storage:OfflineStorage) {
		super(api);
	}

	/*
	* Login with username/password.
	*/
	loginCustomer(customerDTO:CustomerDTO) : Observable<CustomerDTO>{
		return this.api.post("CustomerService/loginCustomer", customerDTO, true);
	}
	/*
	* New customer registration
	*/
	signUpCustomer(customerDTO:CustomerDTO) : Observable<CustomerDTO>{
		return this.api.post("CustomerService/signUpCustomer", customerDTO, true);

	}

	completeVerification(customerDTO:CustomerDTO) : Observable<CustomerDTO>{

        return this.api.post("CustomerService/completeVerification", customerDTO, true);
    }

	/*
	* To generate OTP for login and send notification
	*/
	sendLoginOTPVerification(customerDTO:CustomerDTO) : Observable<CustomerDTO>{

        return this.api.post("CustomerService/sendLoginOTPVerification", customerDTO, true);
    }

	resendCustomerVerificationCode(userDTO:UserDTO) : Observable<CustomerDTO>{

		return this.api.post("CustomerService/resendVerificationCode", userDTO, true);
	}

	forgetPasswordCustomer(){

	}
	// Used for login to verify if there is account with mobile and/OR email 
	checkCustomerExistance(customerDTO:CustomerDTO) : Observable<boolean> {

        let searchCustomerDTO:CustomerDTO = new CustomerDTO();
        searchCustomerDTO.mobileNo = customerDTO.mobileNo;
        searchCustomerDTO.mobileExt = customerDTO.mobileExt;
        searchCustomerDTO.email = customerDTO.email;

        return this.api.post("CustomerService/checkCustomerExistance", searchCustomerDTO, true);
	}

	//Used in change email , to verify if there is email used by other users ( not for this customer ID)
	checkEmailExistanceByOthers(email:string, userId:number ) : Observable<boolean> {
        let customerDTO:CustomerDTO = new CustomerDTO();
		customerDTO.email = email;
		customerDTO.id = userId; 
        return this.checkEmailExistance(customerDTO);
    }
	//Used in change email , to verify if there is email used by other users ( not for this customer ID)
	checkMobileExistanceByOthers(mobileNo:number, mobileExt:string,userId:number ) : Observable<boolean> {
        let customerDTO:CustomerDTO = new CustomerDTO();
		customerDTO.mobileNo = mobileNo;
		customerDTO.mobileExt = mobileExt; 
        return this.checkMobileExistance(customerDTO);
    }
	
	checkMobileExistance(customerDTO:CustomerDTO) : Observable<boolean> {
       
        return this.api.postNoLoading("CustomerService/checkMobileExistance", customerDTO);
    }

	checkEmailExistance(customerDTO:CustomerDTO) : Observable<boolean> {
        
        return this.api.postNoLoading("CustomerService/checkEmailExistance", customerDTO);
    }

	

	updateLanguage(customerDTO:CustomerDTO){

        return this.api.post("CustomerService/updateLanguage", customerDTO, true);
	}
	
	checkUserPassword(customerDTO:CustomerDTO) : Observable<CustomerDTO>{

        return this.api.post("CustomerService/checkUserPassword", customerDTO, true);
	}
	
	updatePassword(customerDTO:CustomerDTO) : Observable<number> {

        return this.api.post("CustomerService/updatePassword", customerDTO, true);
	}

	updateEmail(customerDTO:CustomerDTO) : Observable<number> {

        return this.api.post("CustomerService/updateEmail", customerDTO, true);
	}

	updateMobile(customerDTO:CustomerDTO) : Observable<number> {

        return this.api.post("CustomerService/updateMobile", customerDTO, true);
	}

	updatePersonalizationQuestions(customerDTO:CustomerDTO) : Observable<number> {

        return this.api.post("CustomerService/updatePersonalizationQuestions", customerDTO, true);
	}
	
	
	getApprovedCustomerOfferListByCustomerId(customerId:number) : Observable<CustomerOfferDTO[]> {

		return this.api.get<CustomerOfferDTO[]>("CustomerService/getApprovedCustomerOfferListByCustomerId/"+customerId, true );
	}

	getApprovedCustomerOfferListByCustomerOfferId(customerOfferId:number) : Observable<CustomerOfferDTO[]> {

		return this.api.get<CustomerOfferDTO[]>("CustomerService/getApprovedCustomerOfferListByCustomerOfferId/"+customerOfferId, true );
	}

	getPendingCustomerOfferListByCustomerId(customerId:number) : Observable<DealsListingModel> {

		return this.api.getNoLoading<DealsListingModel>("CustomerService/getPendingCustomerOfferListByCustomerId/"+customerId );		
	}

	getGeneratedCustomerOfferList(customerOfferIdList:Array<number>) : Observable<DealsListingModel>{
		
		return this.api.postNoLoading("CustomerService/getGeneratedCustomerOfferList", customerOfferIdList);
	}

	getCustomerOffersList(customerId:number) : Observable<Array<CustomerOfferDTO>> {

		return this.api.get<Array<CustomerOfferDTO>>("CustomerService/getCustomerOffersList/"+customerId, true );
		
	}

	getCustomerProfile(customerId:number) : Observable<CustomerDTO> {

		return this.api.get<CustomerDTO>("CustomerService/getCustomerProfile/"+customerId, true );
	}

	updateCustomerProfile(customerDTO:CustomerDTO) : Observable<CustomerDTO> {

        return this.api.post("CustomerService/updateCustomerProfile", customerDTO, true);
	}

	uploadCustomerPhoto(customerDTO:CustomerDTO) : Observable<CustomerDTO> {

        return this.api.post("CustomerService/uploadCustomerPhoto", customerDTO, true);
	}


	approveCustomerOffer(customerOfferId:number) :  Observable<CustomerOfferDTO> {

        return this.api.post<CustomerOfferDTO>("CustomerService/approveCustomerOffer/"+customerOfferId, customerOfferId, true);
	}

	rejectOffersByCustomer(customerOfferIdList:Array<number>){

        return this.api.post("CustomerService/rejectOffersByCustomer", customerOfferIdList, true);
	}

	cancelCustomerOffer(customerOfferId:number){

        return this.api.post("CustomerService/cancelCustomerOffer/"+customerOfferId, customerOfferId, true);
	}

	
	cancelExpiredOffers(customerOfferIdList:Array<number>){

        return this.api.post("CustomerService/cancelExpiredOffers", customerOfferIdList, true);
	}

	updateOfferRejectionReason(rejectionReasonId:number,  customerOfferIdList:Array<number>){

        return this.api.post("CustomerService/updateOfferRejectionReason/"+rejectionReasonId, customerOfferIdList, true);
	}

	getTcodeOccupationList() : Observable<Array<TcodeOccupationDTO>> {

		return this.api.get<Array<TcodeOccupationDTO>>("CustomerService/getTcodeOccupationList", false );
	}
	
	generateCustomerOffers(generateCustomerOffers:GenerateCustomerOfferDTO){
        return this.api.post("CustomerService/generateCustomerOffers", generateCustomerOffers, true);
	}


	resetPassword(emailId:String){

        return this.api.post("CustomerService/resetPassword", emailId, true);
	}
}