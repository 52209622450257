import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {Api} from './api.service';
import {CustomerCuisinesDTO} from '../dto/customer-cuisines-dto';
import {CustomerDTO} from '../dto/customer-dto';

@Injectable()
export class BaseCustomerCuisinesService {

	constructor(public api:Api) {

	}

	getCustomerCuisinesById(id:number) : Observable<CustomerCuisinesDTO> {

		return this.api.post<CustomerCuisinesDTO>("CustomerCuisines/getCustomerCuisinesById", id , false );
	}

	getCustomerCuisines(customerCuisinesDTO:CustomerCuisinesDTO) : Observable<CustomerCuisinesDTO> {

		return this.api.post<CustomerCuisinesDTO>("CustomerCuisines/getCustomerCuisines", customerCuisinesDTO, false );
	}

	getCustomerCuisinessByCustomerId(customerDTO:CustomerDTO) : Observable<CustomerDTO> {

		return this.api.post<CustomerDTO>("CustomerCuisines/getCustomerCuisinessByCustomerId", customerDTO, false );
	}

	getCustomerCuisinesListByCustomerId(customerId:number) : Observable<Array<CustomerCuisinesDTO>> {

		return this.api.post<Array<CustomerCuisinesDTO>>("CustomerCuisines/getCustomerCuisinesListByCustomerId", customerId, false );
	}

	getCustomerCuisinesMapByCustomerId(customerId:number) : Observable<Map<number, CustomerCuisinesDTO>>  {

		return this.api.post<Map<number, CustomerCuisinesDTO>>("CustomerCuisines/getCustomerCuisinesMapByCustomerId", customerId, false );
	}

	getCustomerCuisinesList() : Observable<CustomerCuisinesDTO[]> {

		return this.api.get<CustomerCuisinesDTO[]>("CustomerCuisines/getCustomerCuisinesList", false );
	}

	getCustomerCuisinesMap() : Observable<Map<number, CustomerCuisinesDTO>> {

		return this.api.get<Map<number, CustomerCuisinesDTO>>("CustomerCuisines/getCustomerCuisinesMap", false );
	}

	searchCustomerCuisinesList(customerCuisinesDTO:CustomerCuisinesDTO) : Observable<Array<CustomerCuisinesDTO>> {

		return this.api.post<Array<CustomerCuisinesDTO>>("CustomerCuisines/searchCustomerCuisinesList", customerCuisinesDTO, false );
	}

	searchCustomerCuisinesMap(customerCuisinesDTO:CustomerCuisinesDTO) : Observable<Map<number, CustomerCuisinesDTO>> {

		return this.api.post<Map<number, CustomerCuisinesDTO>>("CustomerCuisines/searchCustomerCuisinesMap", customerCuisinesDTO, false );
	}

	addCustomerCuisines(customerCuisinesDTO:CustomerCuisinesDTO) : Observable<CustomerCuisinesDTO> {

		return this.api.post<CustomerCuisinesDTO>("CustomerCuisines/addCustomerCuisines", customerCuisinesDTO, true );
	}

	addCustomerCuisinesList(customerCuisinesList:Array<CustomerCuisinesDTO>) : Observable<Array<CustomerCuisinesDTO>> {

		return this.api.post<Array<CustomerCuisinesDTO>>("CustomerCuisines/addCustomerCuisinesList", customerCuisinesList, true );
	}

	updateCustomerCuisines(customerCuisinesDTO:CustomerCuisinesDTO) : Observable<CustomerCuisinesDTO> {

		return this.api.post<CustomerCuisinesDTO>("CustomerCuisines/updateCustomerCuisines", customerCuisinesDTO, true );
	}

	updateCustomerCuisinesList(customerCuisinesList:Array<CustomerCuisinesDTO>) : Observable<Array<CustomerCuisinesDTO>> {

		return this.api.post<Array<CustomerCuisinesDTO>>("CustomerCuisines/updateCustomerCuisinesList", customerCuisinesList, true );
	}

	saveCustomerCuisines(customerCuisinesDTO:CustomerCuisinesDTO) : Observable<CustomerCuisinesDTO> {

		return this.api.post<CustomerCuisinesDTO>("CustomerCuisines/saveCustomerCuisines", customerCuisinesDTO, true );
	}

	saveCustomerCuisinesList(customerCuisinesList:Array<CustomerCuisinesDTO>) : Observable<Array<CustomerCuisinesDTO>> {

		return this.api.post<Array<CustomerCuisinesDTO>>("CustomerCuisines/saveCustomerCuisinesList", customerCuisinesList, true );
	}

	activateCustomerCuisinesById(id:number) : Observable<number>{

		return this.api.post<number>("CustomerCuisines/activateCustomerCuisinesById", id, true );
	}

	activateCustomerCuisines(customerCuisinesDTO:CustomerCuisinesDTO) : Observable<CustomerCuisinesDTO> {

		return this.api.post<CustomerCuisinesDTO>("CustomerCuisines/activateCustomerCuisines", customerCuisinesDTO, true );
	}

	activateCustomerCuisinesList(customerCuisinesList:Array<CustomerCuisinesDTO>) : Observable<Array<CustomerCuisinesDTO>> {

		return this.api.post<Array<CustomerCuisinesDTO>>("CustomerCuisines/activateCustomerCuisinesList", customerCuisinesList, true );
	}

	deactivateCustomerCuisinesById(id:number) : Observable<number> {

		return this.api.post<number>("CustomerCuisines/deactivateCustomerCuisinesById", id, true );
	}

	deactivateCustomerCuisines(customerCuisinesDTO:CustomerCuisinesDTO) : Observable<CustomerCuisinesDTO> {

		return this.api.post<CustomerCuisinesDTO>("CustomerCuisines/deactivateCustomerCuisines", customerCuisinesDTO, true );
	}

	deactivateCustomerCuisinesList(customerCuisinesList:Array<CustomerCuisinesDTO>) : Observable<Array<CustomerCuisinesDTO>> {

		return this.api.post<Array<CustomerCuisinesDTO>>("CustomerCuisines/deactivateCustomerCuisinesList", customerCuisinesList, true );
	}
}