import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {Api} from '../base-service/api.service';
import { BaseCustomerOfferService } from '../base-service/base-customer-offer.service';
import { CustomerOfferDTO } from '../dto/customer-offer-dto';

@Injectable()
export class CustomerOfferService extends BaseCustomerOfferService{

	constructor(public api:Api) {
		super(api);
	}

}