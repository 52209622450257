import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {Api} from './api.service';
import {TcodeRejectionReasonDTO} from '../dto/tcode-rejection-reason-dto';

@Injectable()
export class BaseTcodeRejectionReasonService {

	constructor(public api:Api) {

	}

	getTcodeRejectionReasonById(id:number) : Observable<TcodeRejectionReasonDTO> {

		return this.api.post<TcodeRejectionReasonDTO>("TcodeRejectionReason/getTcodeRejectionReasonById", id , false);
	}

	getTcodeRejectionReason(tcodeRejectionReasonDTO:TcodeRejectionReasonDTO) : Observable<TcodeRejectionReasonDTO> {

		return this.api.post<TcodeRejectionReasonDTO>("TcodeRejectionReason/getTcodeRejectionReason", tcodeRejectionReasonDTO , false);
	}

	getTcodeRejectionReasonList() : Observable<TcodeRejectionReasonDTO[]> {

		return this.api.get<TcodeRejectionReasonDTO[]>("TcodeRejectionReason/getTcodeRejectionReasonList" , false);
	}

	getTcodeRejectionReasonMap() : Observable<Map<number, TcodeRejectionReasonDTO>> {

		return this.api.get<Map<number, TcodeRejectionReasonDTO>>("TcodeRejectionReason/getTcodeRejectionReasonMap" , false);
	}

	searchTcodeRejectionReasonList(tcodeRejectionReasonDTO:TcodeRejectionReasonDTO) : Observable<Array<TcodeRejectionReasonDTO>> {

		return this.api.post<Array<TcodeRejectionReasonDTO>>("TcodeRejectionReason/searchTcodeRejectionReasonList", tcodeRejectionReasonDTO , false);
	}

	searchTcodeRejectionReasonMap(tcodeRejectionReasonDTO:TcodeRejectionReasonDTO) : Observable<Map<number, TcodeRejectionReasonDTO>> {

		return this.api.post<Map<number, TcodeRejectionReasonDTO>>("TcodeRejectionReason/searchTcodeRejectionReasonMap", tcodeRejectionReasonDTO , false);
	}

	addTcodeRejectionReason(tcodeRejectionReasonDTO:TcodeRejectionReasonDTO) : Observable<TcodeRejectionReasonDTO> {

		return this.api.post<TcodeRejectionReasonDTO>("TcodeRejectionReason/addTcodeRejectionReason", tcodeRejectionReasonDTO , false);
	}

	addTcodeRejectionReasonList(tcodeRejectionReasonList:Array<TcodeRejectionReasonDTO>) : Observable<Array<TcodeRejectionReasonDTO>> {

		return this.api.post<Array<TcodeRejectionReasonDTO>>("TcodeRejectionReason/addTcodeRejectionReasonList", tcodeRejectionReasonList , false);
	}

	updateTcodeRejectionReason(tcodeRejectionReasonDTO:TcodeRejectionReasonDTO) : Observable<TcodeRejectionReasonDTO> {

		return this.api.post<TcodeRejectionReasonDTO>("TcodeRejectionReason/updateTcodeRejectionReason", tcodeRejectionReasonDTO , false);
	}

	updateTcodeRejectionReasonList(tcodeRejectionReasonList:Array<TcodeRejectionReasonDTO>) : Observable<Array<TcodeRejectionReasonDTO>> {

		return this.api.post<Array<TcodeRejectionReasonDTO>>("TcodeRejectionReason/updateTcodeRejectionReasonList", tcodeRejectionReasonList , false);
	}

	saveTcodeRejectionReason(tcodeRejectionReasonDTO:TcodeRejectionReasonDTO) : Observable<TcodeRejectionReasonDTO> {

		return this.api.post<TcodeRejectionReasonDTO>("TcodeRejectionReason/saveTcodeRejectionReason", tcodeRejectionReasonDTO , false);
	}

	saveTcodeRejectionReasonList(tcodeRejectionReasonList:Array<TcodeRejectionReasonDTO>) : Observable<Array<TcodeRejectionReasonDTO>> {

		return this.api.post<Array<TcodeRejectionReasonDTO>>("TcodeRejectionReason/saveTcodeRejectionReasonList", tcodeRejectionReasonList , false);
	}

	activateTcodeRejectionReasonById(id:number) : Observable<number>{

		return this.api.post<number>("TcodeRejectionReason/activateTcodeRejectionReasonById", id , false);
	}

	activateTcodeRejectionReason(tcodeRejectionReasonDTO:TcodeRejectionReasonDTO) : Observable<TcodeRejectionReasonDTO> {

		return this.api.post<TcodeRejectionReasonDTO>("TcodeRejectionReason/activateTcodeRejectionReason", tcodeRejectionReasonDTO , false);
	}

	activateTcodeRejectionReasonList(tcodeRejectionReasonList:Array<TcodeRejectionReasonDTO>) : Observable<Array<TcodeRejectionReasonDTO>> {

		return this.api.post<Array<TcodeRejectionReasonDTO>>("TcodeRejectionReason/activateTcodeRejectionReasonList", tcodeRejectionReasonList , false);
	}

	deactivateTcodeRejectionReasonById(id:number) : Observable<number> {

		return this.api.post<number>("TcodeRejectionReason/deactivateTcodeRejectionReasonById", id , false);
	}

	deactivateTcodeRejectionReason(tcodeRejectionReasonDTO:TcodeRejectionReasonDTO) : Observable<TcodeRejectionReasonDTO> {

		return this.api.post<TcodeRejectionReasonDTO>("TcodeRejectionReason/deactivateTcodeRejectionReason", tcodeRejectionReasonDTO , false);
	}

	deactivateTcodeRejectionReasonList(tcodeRejectionReasonList:Array<TcodeRejectionReasonDTO>) : Observable<Array<TcodeRejectionReasonDTO>> {

		return this.api.post<Array<TcodeRejectionReasonDTO>>("TcodeRejectionReason/deactivateTcodeRejectionReasonList", tcodeRejectionReasonList , false);
	}
}