import {SupplierDTO} from '../dto/supplier-dto';
import {RoleDTO} from '../dto/role-dto';
import {LoginHistDTO} from '../dto/login-hist-dto';
import {DeviceDTO} from '../dto/device-dto';
import {CustomerDTO} from '../dto/customer-dto';

export class BaseUserDTO {

	id:number;
	email:string;
	mobileExt:string;
	mobileNo:number;
	password:string;
	roleDTO:RoleDTO = new RoleDTO();
	supplierDTO:SupplierDTO = new SupplierDTO();
	registerationTime:string;
	language:string;
	verificationCode:number;
	active:boolean;
	recCrtDate:string;
	recUpdtDate:string;
	recCrtUser:number;
	recUpdtUser:number;

	loginHistList:Array<LoginHistDTO> = new Array<LoginHistDTO>();
	deviceList:Array<DeviceDTO> = new Array<DeviceDTO>();
	customerList:Array<CustomerDTO> = new Array<CustomerDTO>();
}