import {TcodeCountryDTO} from '../dto/tcode-country-dto';
import {SupplierDTO} from '../dto/supplier-dto';
import {CustomerDTO} from '../dto/customer-dto';

export class BaseTcodeCityDTO {

	id:number;
	englishName:string;
	arabicName:string;
	countryDTO:TcodeCountryDTO = new TcodeCountryDTO();
	active:boolean;
	recCrtDate:string;
	recUpdtDate:string;
	recCrtUser:number;
	recUpdtUser:number;

	supplierList:Array<SupplierDTO> = new Array<SupplierDTO>();
	customerList:Array<CustomerDTO> = new Array<CustomerDTO>();
}