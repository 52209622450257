import { HttpClient, HttpHeaders, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import {Observable} from 'rxjs';
import { catchError, tap } from 'rxjs/operators';
//import { _throw } from 'rxjs/observable/throw';
import { LoadingService } from '../services/loading.service';
import { OfflineStorage } from '../services/storage.service';
import { Subscriber } from 'rxjs';
import { Alert } from '../services/alert.service';

@Injectable()
export class Api {

    // url:string = "http://localhost:8080/services/";//Local
    url:string = "http://136.244.96.179/Fados/services/";//UAT
    //url:string = "https://fados.app/services/"  ; // Production
    
    static privacyPolicyURL:string ="https://google.com/search?q=privacyPolicy";
    static termsAndConditionsURL:string ="https://google.com/search?q=TermsPolicy";


    constructor(public http: HttpClient, public loading:LoadingService, public storage:OfflineStorage ,   private alert:Alert) {
    }


    /**
     * 
            https://ionicframework.com/docs/api/loading
     */
    
    get<T>(endpoint: string, wait:boolean) : Observable<T> {
        const headers = {headers: new HttpHeaders({"Content-Type" :  "application/json; charset=utf-8"})};

        return new Observable<T>((subscriber: Subscriber<T>) => {
            if(wait){
                this.loading.presentLoading();
                
            } else {

                this.storage.get(endpoint).then((data)=>{

                    if(data != null){

                        subscriber.next(data);
                    }else{

                        this.loading.presentLoading();
                    }
                });
            }

            this.http.get<T>(this.url + endpoint, headers).subscribe((result:T)=>{

                if(!wait){

                    this.storage.set(endpoint, result);
                }

                this.loading.dismiss(); 

                subscriber.next(result);

            }
            , (error:any)=> {
                //console.log(JSON.stringify(error));
              
                this.loading.dismiss(); 

                subscriber.error(error);
            });
        });
    }

     post<T>(endpoint: string, body:any, wait:boolean) : Observable<T> {

        const headers = {headers: new HttpHeaders({"Content-Type" :  "application/json; charset=utf-8"})};

         
        return new Observable<T>((subscriber: Subscriber<T>) => {
            
            if(wait){
                this.loading.presentLoading();
                
            } else {

                this.storage.get(endpoint + "/" + JSON.stringify(body)).then((data)=>{

                    if(data != null){
                      
                        subscriber.next(data);
                        
                    }else{
    
                        this.loading.presentLoading();
                    }
                });
            }

            this.http.post<T>(this.url + endpoint, body, headers).subscribe((result:T)=>{
                if(!wait){
                    
                    this.storage.set(endpoint + "/" + JSON.stringify(body), result);
                }
                this.loading.dismiss();

                subscriber.next(result);

            }, (error:any)=> {

                this.loading.dismiss(); 

                subscriber.error(error);
            });
        });
    }

    postNoLoading<T>(endpoint: string, body:any) : Observable<T> {
 
        const headers = {headers: new HttpHeaders({"Content-Type" :  "application/json; charset=utf-8"})};
 
        return this.http.post<T>(this.url + endpoint, body, headers);
    }

    getNoLoading<T>(endpoint: string) : Observable<T> {
 
        const headers = {headers: new HttpHeaders({"Content-Type" :  "application/json; charset=utf-8"})};
 
        return this.http.get<T>(this.url + endpoint, headers);
    }

}