import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

const routes: Routes = [
  {
    path: '',
    redirectTo: '/home',
    pathMatch: 'full'
  },
  {
    path: 'walkthrough',
    loadChildren: () => import('./walkthrough/walkthrough.module').then(m => m.WalkthroughPageModule)
  },
  // {
  //   path: 'getting-started',
  //   loadChildren: () => import('./getting-started/getting-started.module').then(m => m.GettingStartedPageModule)
  // },
  {
    path: 'auth/login',
    loadChildren: () => import('./login-signUp-main/login-SignUp-main.module').then(m => m.LoginSignUpMainModule)
  },
  // {
  //   path: 'auth/signup',
  //   loadChildren: () => import('./signup/signup.module').then(m => m.SignupPageModule)
  // },
  {
    path: 'auth/forgot-password',
    loadChildren: () => import('./forgot-password/forgot-password.module').then(m => m.ForgotPasswordPageModule)
  },
  // {
  //   path: 'app',
  //   loadChildren: () => import('./tabs/tabs.module').then(m => m.TabsPageModule)
  // },
  {
    path: 'offers-listing/:id1/:id2/:id3',
    loadChildren: () => import('./deals/listing/deals-listing.module').then(m => m.DealsListingPageModule)
  },
  {
    path: 'coupon',
    loadChildren: () => import('./deals/details/deals-details.module').then(m => m.DealsDetailsPageModule)
  },
  {
    path: 'reject',
    loadChildren: () => import('./deals/rejection-reason/rejection-reason.module').then(m => m.RejectionReasonPageModule)
  },
  // {
  //   path: 'contact-card',
  //   loadChildren: () => import('./contact-card/contact-card.module').then(m => m.ContactCardPageModule)
  // },
  // {
  //   path: 'forms-and-validations',
  //   loadChildren: () => import('./forms/validations/forms-validations.module').then(m => m.FormsValidationsPageModule)
  // },
  // {
  //   path: 'forms-filters',
  //   loadChildren: () => import('./forms/filters/forms-filters.module').then(m => m.FormsFiltersPageModule)
  // },
  {
    path: 'page-not-found',
    loadChildren: () => import('./page-not-found/page-not-found.module').then(m => m.PageNotFoundModule)
  },
  // {
  //   path: 'showcase',
  //   loadChildren: () => import('./showcase/showcase.module').then(m => m.ShowcasePageModule)
  // },
  // {
  //   path: 'firebase',
  //   redirectTo: 'firebase/auth/sign-in',
  //   pathMatch: 'full'
  // },
  // {
  //   path: 'firebase/auth',
  //   loadChildren: () => import('./firebase/auth/firebase-auth.module').then(m => m.FirebaseAuthModule)
  // },
  // {
  //   path: 'firebase/crud',
  //   loadChildren: () => import('./firebase/crud/firebase-crud.module').then(m => m.FirebaseCrudModule)
  // },
  // {
  //   path: 'maps',
  //   loadChildren: () => import('./maps/maps.module').then(m => m.MapsPageModule)
  // },
  // {
  //   path: 'video-playlist',
  //   loadChildren: () => import('./video-playlist/video-playlist.module').then(m => m.VideoPlaylistPageModule)
  // },
  // {
  //   path: 'user',
  //   loadChildren: () => import('./user/profile/user-profile.module').then(m => m.UserProfilePageModule)
  // },
  {
    path: 'profile',
    loadChildren: () => import('./profile/profile.module').then(m => m.ProfilePageModule)
  },
  {
    path: 'home',
    loadChildren: () => import('./home/home.module').then(m => m.HomePageModule)
  },
  {
    path: 'suggestions',
    loadChildren: () => import('./suggestions/suggestions.module').then(m => m.SuggestionsPageModule)
  },
  // {
  //   path: 'offer-filters',
  //   loadChildren: () => import('./offer-filter-questions/offer-filter/offe-filters.module').then(m => m.OfferFiltersPageModule)
  // },
  {
    path: 'offer-filter/question',
    loadChildren: () => import('./offer-filter-questions/offer-question/offer-question.module').then(m => m.OfferQuestionPageModule)
  },
  {
    path: 'personalization-questions', 
    loadChildren: () => import('./personalization-questions/personalization-questions.module').then(m => m.PersonalizationQuestionsPageModule)
  },
  // {
  //   path: 'explore_restaurant',
  //   loadChildren: () => import('./restaurants/restaurants.module').then(m => m.RestaurantsPageModule)
  // },
  {
    path: 'settings',
    loadChildren: () => import('./settings/settings.module').then( m => m.SettingsPageModule)
  },

  {
    path: 'about',
    loadChildren: () => import('./about/about.module').then( m => m.AboutPageModule)
  },
  {
    path: '**',
    redirectTo: 'page-not-found'
  }
];
@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      // This value is required for server-side rendering to work.
      initialNavigation: 'enabled',
      scrollPositionRestoration: 'enabled',
      anchorScrolling: 'enabled'
    })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule {}
