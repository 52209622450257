import {TcodeRestDestinationDTO} from '../dto/tcode-rest-destination-dto';
import {RestaurantDTO} from '../dto/restaurant-dto';

export class BaseRestaurantDestinationDTO {

	id:number;
	restaurantDTO:RestaurantDTO = new RestaurantDTO();
	destinationDTO:TcodeRestDestinationDTO = new TcodeRestDestinationDTO();
	active:boolean;
	recCrtDate:string;
	recUpdtDate:string;
	recCrtUser:number;
	recUpdtUser:number;
}