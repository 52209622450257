import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {Api} from './api.service';
import {OfferDTO} from '../dto/offer-dto';
import {SupplierDTO} from '../dto/supplier-dto';
import { DealsListingModel } from '../deals/listing/deals-listing.model';

@Injectable()
export class BaseOfferService {

	constructor(public api:Api) {

	}

	getOfferById(id:number) : Observable<OfferDTO> {

		return this.api.post<OfferDTO>("Offer/getOfferById", id, false );
	}

	getOffer(offerDTO:OfferDTO) : Observable<OfferDTO> {

		return this.api.post<OfferDTO>("Offer/getOffer", offerDTO, false );
	}

	getOffersBySupplierId(supplierDTO:SupplierDTO) : Observable<SupplierDTO> {

		return this.api.post<SupplierDTO>("Offer/getOffersBySupplierId", supplierDTO, false );
	}

	getOfferListBySupplierId(supplierId:number) : Observable<Array<OfferDTO>> {

		return this.api.post<Array<OfferDTO>>("Offer/getOfferListBySupplierId", supplierId, false );
	}

	getOfferMapBySupplierId(supplierId:number) : Observable<Map<number, OfferDTO>>  {

		return this.api.post<Map<number, OfferDTO>>("Offer/getOfferMapBySupplierId", supplierId, false );
	}

	getOfferList() : Observable<DealsListingModel> {

		return this.api.get<DealsListingModel>("Offer/getOfferList", false );
	}

	getOfferMap() : Observable<Map<number, OfferDTO>> {

		return this.api.get<Map<number, OfferDTO>>("Offer/getOfferMap", false );
	}

	searchOfferList(offerDTO:OfferDTO) : Observable<Array<OfferDTO>> {

		return this.api.post<Array<OfferDTO>>("Offer/searchOfferList", offerDTO, false );
	}

	searchOfferMap(offerDTO:OfferDTO) : Observable<Map<number, OfferDTO>> {

		return this.api.post<Map<number, OfferDTO>>("Offer/searchOfferMap", offerDTO, false );
	}

	addOffer(offerDTO:OfferDTO) : Observable<OfferDTO> {

		return this.api.post<OfferDTO>("Offer/addOffer", offerDTO, false );
	}

	addOfferList(offerList:Array<OfferDTO>) : Observable<Array<OfferDTO>> {

		return this.api.post<Array<OfferDTO>>("Offer/addOfferList", offerList, false );
	}

	updateOffer(offerDTO:OfferDTO) : Observable<OfferDTO> {

		return this.api.post<OfferDTO>("Offer/updateOffer", offerDTO, false );
	}

	updateOfferList(offerList:Array<OfferDTO>) : Observable<Array<OfferDTO>> {

		return this.api.post<Array<OfferDTO>>("Offer/updateOfferList", offerList, false );
	}

	saveOffer(offerDTO:OfferDTO) : Observable<OfferDTO> {

		return this.api.post<OfferDTO>("Offer/saveOffer", offerDTO, false );
	}

	saveOfferList(offerList:Array<OfferDTO>) : Observable<Array<OfferDTO>> {

		return this.api.post<Array<OfferDTO>>("Offer/saveOfferList", offerList, false );
	}

	activateOfferById(id:number) : Observable<number>{

		return this.api.post<number>("Offer/activateOfferById", id, false );
	}

	activateOffer(offerDTO:OfferDTO) : Observable<OfferDTO> {

		return this.api.post<OfferDTO>("Offer/activateOffer", offerDTO, false );
	}

	activateOfferList(offerList:Array<OfferDTO>) : Observable<Array<OfferDTO>> {

		return this.api.post<Array<OfferDTO>>("Offer/activateOfferList", offerList, false );
	}

	deactivateOfferById(id:number) : Observable<number> {

		return this.api.post<number>("Offer/deactivateOfferById", id, false );
	}

	deactivateOffer(offerDTO:OfferDTO) : Observable<OfferDTO> {

		return this.api.post<OfferDTO>("Offer/deactivateOffer", offerDTO, false );
	}

	deactivateOfferList(offerList:Array<OfferDTO>) : Observable<Array<OfferDTO>> {

		return this.api.post<Array<OfferDTO>>("Offer/deactivateOfferList", offerList, false );
	}
}