import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {Api} from './api.service';
import {LoginHistDTO} from '../dto/login-hist-dto';
import {UserDTO} from '../dto/user-dto';
import {DeviceDTO} from '../dto/device-dto';

@Injectable()
export class BaseLoginHistService {

	constructor(public api:Api) {

	}

	getLoginHistById(id:number) : Observable<LoginHistDTO> {

		return this.api.post<LoginHistDTO>("LoginHist/getLoginHistById", id, false );
	}

	getLoginHist(loginHistDTO:LoginHistDTO) : Observable<LoginHistDTO> {

		return this.api.post<LoginHistDTO>("LoginHist/getLoginHist", loginHistDTO, false );
	}

	getLoginHistsByUserId(userDTO:UserDTO) : Observable<UserDTO> {

		return this.api.post<UserDTO>("LoginHist/getLoginHistsByUserId", userDTO, false );
	}

	getLoginHistListByUserId(userId:number) : Observable<Array<LoginHistDTO>> {

		return this.api.post<Array<LoginHistDTO>>("LoginHist/getLoginHistListByUserId", userId, false );
	}

	getLoginHistMapByUserId(userId:number) : Observable<Map<number, LoginHistDTO>>  {

		return this.api.post<Map<number, LoginHistDTO>>("LoginHist/getLoginHistMapByUserId", userId, false );
	}

	getLoginHistsByDeviceId(deviceDTO:DeviceDTO) : Observable<DeviceDTO> {

		return this.api.post<DeviceDTO>("LoginHist/getLoginHistsByDeviceId", deviceDTO, false );
	}

	getLoginHistListByDeviceId(deviceId:number) : Observable<Array<LoginHistDTO>> {

		return this.api.post<Array<LoginHistDTO>>("LoginHist/getLoginHistListByDeviceId", deviceId, false );
	}

	getLoginHistMapByDeviceId(deviceId:number) : Observable<Map<number, LoginHistDTO>>  {

		return this.api.post<Map<number, LoginHistDTO>>("LoginHist/getLoginHistMapByDeviceId", deviceId, false );
	}

	getLoginHistList() : Observable<LoginHistDTO[]> {

		return this.api.get<LoginHistDTO[]>("LoginHist/getLoginHistList", false );
	}

	getLoginHistMap() : Observable<Map<number, LoginHistDTO>> {

		return this.api.get<Map<number, LoginHistDTO>>("LoginHist/getLoginHistMap", false );
	}

	searchLoginHistList(loginHistDTO:LoginHistDTO) : Observable<Array<LoginHistDTO>> {

		return this.api.post<Array<LoginHistDTO>>("LoginHist/searchLoginHistList", loginHistDTO, false );
	}

	searchLoginHistMap(loginHistDTO:LoginHistDTO) : Observable<Map<number, LoginHistDTO>> {

		return this.api.post<Map<number, LoginHistDTO>>("LoginHist/searchLoginHistMap", loginHistDTO, false );
	}

	addLoginHist(loginHistDTO:LoginHistDTO) : Observable<LoginHistDTO> {

		return this.api.post<LoginHistDTO>("LoginHist/addLoginHist", loginHistDTO, false );
	}

	addLoginHistList(loginHistList:Array<LoginHistDTO>) : Observable<Array<LoginHistDTO>> {

		return this.api.post<Array<LoginHistDTO>>("LoginHist/addLoginHistList", loginHistList, false );
	}

	updateLoginHist(loginHistDTO:LoginHistDTO) : Observable<LoginHistDTO> {

		return this.api.post<LoginHistDTO>("LoginHist/updateLoginHist", loginHistDTO, false );
	}

	updateLoginHistList(loginHistList:Array<LoginHistDTO>) : Observable<Array<LoginHistDTO>> {

		return this.api.post<Array<LoginHistDTO>>("LoginHist/updateLoginHistList", loginHistList, false );
	}

	saveLoginHist(loginHistDTO:LoginHistDTO) : Observable<LoginHistDTO> {

		return this.api.post<LoginHistDTO>("LoginHist/saveLoginHist", loginHistDTO, false );
	}

	saveLoginHistList(loginHistList:Array<LoginHistDTO>) : Observable<Array<LoginHistDTO>> {

		return this.api.post<Array<LoginHistDTO>>("LoginHist/saveLoginHistList", loginHistList, false );
	}

	activateLoginHistById(id:number) : Observable<number>{

		return this.api.post<number>("LoginHist/activateLoginHistById", id, false );
	}

	activateLoginHist(loginHistDTO:LoginHistDTO) : Observable<LoginHistDTO> {

		return this.api.post<LoginHistDTO>("LoginHist/activateLoginHist", loginHistDTO, false );
	}

	activateLoginHistList(loginHistList:Array<LoginHistDTO>) : Observable<Array<LoginHistDTO>> {

		return this.api.post<Array<LoginHistDTO>>("LoginHist/activateLoginHistList", loginHistList, false );
	}

	deactivateLoginHistById(id:number) : Observable<number> {

		return this.api.post<number>("LoginHist/deactivateLoginHistById", id, false );
	}

	deactivateLoginHist(loginHistDTO:LoginHistDTO) : Observable<LoginHistDTO> {

		return this.api.post<LoginHistDTO>("LoginHist/deactivateLoginHist", loginHistDTO, false );
	}

	deactivateLoginHistList(loginHistList:Array<LoginHistDTO>) : Observable<Array<LoginHistDTO>> {

		return this.api.post<Array<LoginHistDTO>>("LoginHist/deactivateLoginHistList", loginHistList, false );
	}
}