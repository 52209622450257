import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {Api} from './api.service';
import {TcodeCountryDTO} from '../dto/tcode-country-dto';

@Injectable()
export class BaseTcodeCountryService {

	constructor(public api:Api) {

	}

	getTcodeCountryById(id:number) : Observable<TcodeCountryDTO> {

		return this.api.post<TcodeCountryDTO>("TcodeCountry/getTcodeCountryById", id, false );
	}

	getTcodeCountry(tcodeCountryDTO:TcodeCountryDTO) : Observable<TcodeCountryDTO> {

		return this.api.post<TcodeCountryDTO>("TcodeCountry/getTcodeCountry", tcodeCountryDTO, false );
	}

	getTcodeCountryList() : Observable<TcodeCountryDTO[]> {

		return this.api.get<TcodeCountryDTO[]>("TcodeCountry/getTcodeCountryList", false );
	}

	getTcodeCountryMap() : Observable<Map<number, TcodeCountryDTO>> {

		return this.api.get<Map<number, TcodeCountryDTO>>("TcodeCountry/getTcodeCountryMap", false );
	}

	searchTcodeCountryList(tcodeCountryDTO:TcodeCountryDTO) : Observable<Array<TcodeCountryDTO>> {

		return this.api.post<Array<TcodeCountryDTO>>("TcodeCountry/searchTcodeCountryList", tcodeCountryDTO, false );
	}

	searchTcodeCountryMap(tcodeCountryDTO:TcodeCountryDTO) : Observable<Map<number, TcodeCountryDTO>> {

		return this.api.post<Map<number, TcodeCountryDTO>>("TcodeCountry/searchTcodeCountryMap", tcodeCountryDTO, false );
	}

	addTcodeCountry(tcodeCountryDTO:TcodeCountryDTO) : Observable<TcodeCountryDTO> {

		return this.api.post<TcodeCountryDTO>("TcodeCountry/addTcodeCountry", tcodeCountryDTO, false );
	}

	addTcodeCountryList(tcodeCountryList:Array<TcodeCountryDTO>) : Observable<Array<TcodeCountryDTO>> {

		return this.api.post<Array<TcodeCountryDTO>>("TcodeCountry/addTcodeCountryList", tcodeCountryList, false );
	}

	updateTcodeCountry(tcodeCountryDTO:TcodeCountryDTO) : Observable<TcodeCountryDTO> {

		return this.api.post<TcodeCountryDTO>("TcodeCountry/updateTcodeCountry", tcodeCountryDTO, false );
	}

	updateTcodeCountryList(tcodeCountryList:Array<TcodeCountryDTO>) : Observable<Array<TcodeCountryDTO>> {

		return this.api.post<Array<TcodeCountryDTO>>("TcodeCountry/updateTcodeCountryList", tcodeCountryList, false );
	}

	saveTcodeCountry(tcodeCountryDTO:TcodeCountryDTO) : Observable<TcodeCountryDTO> {

		return this.api.post<TcodeCountryDTO>("TcodeCountry/saveTcodeCountry", tcodeCountryDTO, false );
	}

	saveTcodeCountryList(tcodeCountryList:Array<TcodeCountryDTO>) : Observable<Array<TcodeCountryDTO>> {

		return this.api.post<Array<TcodeCountryDTO>>("TcodeCountry/saveTcodeCountryList", tcodeCountryList, false );
	}

	activateTcodeCountryById(id:number) : Observable<number>{

		return this.api.post<number>("TcodeCountry/activateTcodeCountryById", id, false );
	}

	activateTcodeCountry(tcodeCountryDTO:TcodeCountryDTO) : Observable<TcodeCountryDTO> {

		return this.api.post<TcodeCountryDTO>("TcodeCountry/activateTcodeCountry", tcodeCountryDTO, false );
	}

	activateTcodeCountryList(tcodeCountryList:Array<TcodeCountryDTO>) : Observable<Array<TcodeCountryDTO>> {

		return this.api.post<Array<TcodeCountryDTO>>("TcodeCountry/activateTcodeCountryList", tcodeCountryList, false );
	}

	deactivateTcodeCountryById(id:number) : Observable<number> {

		return this.api.post<number>("TcodeCountry/deactivateTcodeCountryById", id, false );
	}

	deactivateTcodeCountry(tcodeCountryDTO:TcodeCountryDTO) : Observable<TcodeCountryDTO> {

		return this.api.post<TcodeCountryDTO>("TcodeCountry/deactivateTcodeCountry", tcodeCountryDTO, false );
	}

	deactivateTcodeCountryList(tcodeCountryList:Array<TcodeCountryDTO>) : Observable<Array<TcodeCountryDTO>> {

		return this.api.post<Array<TcodeCountryDTO>>("TcodeCountry/deactivateTcodeCountryList", tcodeCountryList, false );
	}
}