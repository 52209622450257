import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable()
export class LoginService {

  private loggedInSource = new BehaviorSubject(false);
  loggedInStatus = this.loggedInSource.asObservable();


  private updateCustomerPhotoSource = new BehaviorSubject(null);
  updateCustomerPhoto = this.updateCustomerPhotoSource.asObservable();
  
  
  // private supplierIdSource = new BehaviorSubject(0);
  // supplierId = this.supplierIdSource.asObservable();
 
  constructor() { }

  setLoggedIn(isLoggedIn:boolean){
    this.loggedInSource.next(isLoggedIn);
  }

  setCustomerPhoto(photo:string){
    this.updateCustomerPhotoSource.next(photo);
  }

  // setSupplierId(supplierId:number){
  //   this.supplierIdSource.next(supplierId);
  // }
  
}