import {CustomerCuisinesDTO} from '../dto/customer-cuisines-dto';
import {RestaurantCuisinesDTO} from '../dto/restaurant-cuisines-dto';

export class BaseTcodeCuisineDTO {

	id:number;
	englishName:string;
	arabicName:string;
	active:boolean;
	recCrtDate:string;
	recUpdtDate:string;
	recCrtUser:number;
	recUpdtUser:number;

	customerCuisinesList:Array<CustomerCuisinesDTO> = new Array<CustomerCuisinesDTO>();
	restaurantCuisinesList:Array<RestaurantCuisinesDTO> = new Array<RestaurantCuisinesDTO>();
}