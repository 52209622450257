import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {Api} from './api.service';
import {TcodeRestDesignDTO} from '../dto/tcode-rest-design-dto';

@Injectable()
export class BaseTcodeRestDesignService {

	constructor(public api:Api) {

	}

	getTcodeRestDesignById(id:number) : Observable<TcodeRestDesignDTO> {

		return this.api.post<TcodeRestDesignDTO>("TcodeRestDesign/getTcodeRestDesignById", id, false );
	}

	getTcodeRestDesign(tcodeRestDesignDTO:TcodeRestDesignDTO) : Observable<TcodeRestDesignDTO> {

		return this.api.post<TcodeRestDesignDTO>("TcodeRestDesign/getTcodeRestDesign", tcodeRestDesignDTO, false );
	}

	getTcodeRestDesignList() : Observable<TcodeRestDesignDTO[]> {

		return this.api.get<TcodeRestDesignDTO[]>("TcodeRestDesign/getTcodeRestDesignList", false );
	}

	getTcodeRestDesignMap() : Observable<Map<number, TcodeRestDesignDTO>> {

		return this.api.get<Map<number, TcodeRestDesignDTO>>("TcodeRestDesign/getTcodeRestDesignMap", false );
	}

	searchTcodeRestDesignList(tcodeRestDesignDTO:TcodeRestDesignDTO) : Observable<Array<TcodeRestDesignDTO>> {

		return this.api.post<Array<TcodeRestDesignDTO>>("TcodeRestDesign/searchTcodeRestDesignList", tcodeRestDesignDTO, false );
	}

	searchTcodeRestDesignMap(tcodeRestDesignDTO:TcodeRestDesignDTO) : Observable<Map<number, TcodeRestDesignDTO>> {

		return this.api.post<Map<number, TcodeRestDesignDTO>>("TcodeRestDesign/searchTcodeRestDesignMap", tcodeRestDesignDTO, false );
	}

	addTcodeRestDesign(tcodeRestDesignDTO:TcodeRestDesignDTO) : Observable<TcodeRestDesignDTO> {

		return this.api.post<TcodeRestDesignDTO>("TcodeRestDesign/addTcodeRestDesign", tcodeRestDesignDTO, false );
	}

	addTcodeRestDesignList(tcodeRestDesignList:Array<TcodeRestDesignDTO>) : Observable<Array<TcodeRestDesignDTO>> {

		return this.api.post<Array<TcodeRestDesignDTO>>("TcodeRestDesign/addTcodeRestDesignList", tcodeRestDesignList, false );
	}

	updateTcodeRestDesign(tcodeRestDesignDTO:TcodeRestDesignDTO) : Observable<TcodeRestDesignDTO> {

		return this.api.post<TcodeRestDesignDTO>("TcodeRestDesign/updateTcodeRestDesign", tcodeRestDesignDTO, false );
	}

	updateTcodeRestDesignList(tcodeRestDesignList:Array<TcodeRestDesignDTO>) : Observable<Array<TcodeRestDesignDTO>> {

		return this.api.post<Array<TcodeRestDesignDTO>>("TcodeRestDesign/updateTcodeRestDesignList", tcodeRestDesignList, false );
	}

	saveTcodeRestDesign(tcodeRestDesignDTO:TcodeRestDesignDTO) : Observable<TcodeRestDesignDTO> {

		return this.api.post<TcodeRestDesignDTO>("TcodeRestDesign/saveTcodeRestDesign", tcodeRestDesignDTO, false );
	}

	saveTcodeRestDesignList(tcodeRestDesignList:Array<TcodeRestDesignDTO>) : Observable<Array<TcodeRestDesignDTO>> {

		return this.api.post<Array<TcodeRestDesignDTO>>("TcodeRestDesign/saveTcodeRestDesignList", tcodeRestDesignList, false );
	}

	activateTcodeRestDesignById(id:number) : Observable<number>{

		return this.api.post<number>("TcodeRestDesign/activateTcodeRestDesignById", id, false );
	}

	activateTcodeRestDesign(tcodeRestDesignDTO:TcodeRestDesignDTO) : Observable<TcodeRestDesignDTO> {

		return this.api.post<TcodeRestDesignDTO>("TcodeRestDesign/activateTcodeRestDesign", tcodeRestDesignDTO, false );
	}

	activateTcodeRestDesignList(tcodeRestDesignList:Array<TcodeRestDesignDTO>) : Observable<Array<TcodeRestDesignDTO>> {

		return this.api.post<Array<TcodeRestDesignDTO>>("TcodeRestDesign/activateTcodeRestDesignList", tcodeRestDesignList, false );
	}

	deactivateTcodeRestDesignById(id:number) : Observable<number> {

		return this.api.post<number>("TcodeRestDesign/deactivateTcodeRestDesignById", id, false );
	}

	deactivateTcodeRestDesign(tcodeRestDesignDTO:TcodeRestDesignDTO) : Observable<TcodeRestDesignDTO> {

		return this.api.post<TcodeRestDesignDTO>("TcodeRestDesign/deactivateTcodeRestDesign", tcodeRestDesignDTO, false );
	}

	deactivateTcodeRestDesignList(tcodeRestDesignList:Array<TcodeRestDesignDTO>) : Observable<Array<TcodeRestDesignDTO>> {

		return this.api.post<Array<TcodeRestDesignDTO>>("TcodeRestDesign/deactivateTcodeRestDesignList", tcodeRestDesignList, false );
	}
}