import {TcodeSpecialityDTO} from '../dto/tcode-speciality-dto';
import {RestaurantDTO} from '../dto/restaurant-dto';

export class BaseRestaurantSpecialityDTO {

	id:number;
	restaurantDTO:RestaurantDTO = new RestaurantDTO();
	specialityDTO:TcodeSpecialityDTO = new TcodeSpecialityDTO();
	active:boolean;
	recCrtDate:string;
	recUpdtDate:string;
	recCrtUser:number;
	recUpdtUser:number;
}