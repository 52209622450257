import { Injectable } from '@angular/core';
import { Storage } from '@ionic/storage';
import { CustomerDTO } from '../dto/customer-dto';
import { CustomerOfferDTO } from '../dto/customer-offer-dto';
import { TcodeCityDTO } from '../dto/tcode-city-dto';



@Injectable()
export class OfflineStorage {

    constructor(private storage: Storage) {

    }
    
    set(name:string, value:any) : Promise<any> {

        return this.storage.set(name, value);
    }

    get(name:string) : Promise<any> {

        return this.storage.get(name);
    }

    // saveUser(userDTO:UserDTO) : Promise<any> {

    //     this.set("loggedIn", true);
        
    //     return this.set("userDTO", userDTO);
    // }

    isLoggedIn() : Promise<boolean> {

        return this.get("loggedIn");
    }

    saveCustomer(customerDTO:CustomerDTO) : Promise<any> {
        this.set("loggedIn", true);

        return  this.set("customerDTO", customerDTO);
    }

    
    fetchCustomer() : Promise<CustomerDTO> {

        return this.get("customerDTO");
    }

    
    clearUser(): Promise<any>{
        //Clears all storage except for the walkthrougDisplayed
        // Intentionally do it like below instead of storage.clear so we want to keep the "walkthrougDisplayed"
        this.storage.remove("customerOfferDTO");
        this.storage.remove("loggedIn");
        return this.storage.remove("customerDTO");
      
    }

    //saveGuestCustomerOffer is for approved guest customer Offer
    saveGuestCustomerOffer(customerOfferDTO:CustomerOfferDTO) : Promise<any> {
        return  this.set("customerOfferDTO", customerOfferDTO);
    }

    // to get the approved guest customer offer
    fetchGuestCustomerOffer() : Promise<CustomerOfferDTO> {

        return this.get("customerOfferDTO");
    }

    clearGuestCustomerOffer(): Promise<any>{
        
        return this.storage.remove("customerOfferDTO");
    }

    saveGuestViewedCustomerOfferList(viewedCustomerOfferList:Array<CustomerOfferDTO>) : Promise<any> {
        return  this.set("viewedCustomerOfferList", viewedCustomerOfferList);
    }

    // to get the approved guest customer offer
    fetchGuestViewedCustomerOfferList() : Promise< Array<CustomerOfferDTO> > {

        return this.get("viewedCustomerOfferList");
    }

    setWalkthrougDisplayed(walkthrougDisplayed:boolean) : Promise<any> {
        console.log("WALKTHORUGH WAS SEEN - NEVER SHOW IT AGAIN TO CUSTOEMR")
        return  this.set("walkthrougDisplayed", walkthrougDisplayed);
    }

    isWalkthrougDisplayed() : Promise<boolean> {

        return this.get("walkthrougDisplayed");
    }

    setGuestLangauge(langauge:string) : Promise<any> {
        return  this.set("guestLangauge", langauge);
    }

    getGuestLangauge() : Promise<string> {

        return this.get("guestLangauge");
    }

    saveCurrentCity(cityDTO:TcodeCityDTO) : Promise<any> {

        return  this.set("currentCityDTO", cityDTO);
    }
    fetchCurrentCity() : Promise<TcodeCityDTO> {

       return this.get("currentCityDTO");
    }

}