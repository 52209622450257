import {CustomerDTO} from '../dto/customer-dto';
import {OfferDTO} from '../dto/offer-dto';
import {TcodeRejectionReasonDTO} from '../dto/tcode-rejection-reason-dto';

export class BaseCustomerOfferDTO {

	id:number;
	offerDTO:OfferDTO = new OfferDTO();
	customerDTO:CustomerDTO = new CustomerDTO();
	generatedTimestamp:string;
	approved:boolean;
	approvedTimestamp:string;
	rejectionDTO:TcodeRejectionReasonDTO = new TcodeRejectionReasonDTO();
	consumed:boolean;
	consumedTimestamp:string;
	rate:number;
	active:boolean;
	recCrtDate:string;
	recUpdtDate:string;
	recCrtUser:number;
	recUpdtUser:number;
	validityTimestamp:string;
}