import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {Api} from './api.service';
import {CustomerDTO} from '../dto/customer-dto';
import {TcodeCityDTO} from '../dto/tcode-city-dto';
import {TcodeOccupationDTO} from '../dto/tcode-occupation-dto';

@Injectable()
export class BaseCustomerService {

	constructor(public api:Api) {

	}

	getCustomerById(id:number) : Observable<CustomerDTO> {

		return this.api.post<CustomerDTO>("Customer/getCustomerById", id, false );
	}

	getCustomer(customerDTO:CustomerDTO) : Observable<CustomerDTO> {

		return this.api.post<CustomerDTO>("Customer/getCustomer", customerDTO, false );
	}

	getCustomersByCityId(tcodeCityDTO:TcodeCityDTO) : Observable<TcodeCityDTO> {

		return this.api.post<TcodeCityDTO>("Customer/getCustomersByCityId", tcodeCityDTO, false );
	}

	getCustomerListByCityId(cityId:number) : Observable<Array<CustomerDTO>> {

		return this.api.post<Array<CustomerDTO>>("Customer/getCustomerListByCityId", cityId, false );
	}

	getCustomerMapByCityId(cityId:number) : Observable<Map<number, CustomerDTO>>  {

		return this.api.post<Map<number, CustomerDTO>>("Customer/getCustomerMapByCityId", cityId, false );
	}

	getCustomersByOccupation(tcodeOccupationDTO:TcodeOccupationDTO) : Observable<TcodeOccupationDTO> {

		return this.api.post<TcodeOccupationDTO>("Customer/getCustomersByOccupation", tcodeOccupationDTO, false );
	}

	getCustomerListByOccupation(occupation:number) : Observable<Array<CustomerDTO>> {

		return this.api.post<Array<CustomerDTO>>("Customer/getCustomerListByOccupation", occupation, false );
	}

	getCustomerMapByOccupation(occupation:number) : Observable<Map<number, CustomerDTO>>  {

		return this.api.post<Map<number, CustomerDTO>>("Customer/getCustomerMapByOccupation", occupation, false );
	}

	getCustomerList() : Observable<CustomerDTO[]> {

		return this.api.get<CustomerDTO[]>("Customer/getCustomerList", false );
	}

	getCustomerMap() : Observable<Map<number, CustomerDTO>> {

		return this.api.get<Map<number, CustomerDTO>>("Customer/getCustomerMap", false );
	}

	searchCustomerList(customerDTO:CustomerDTO) : Observable<Array<CustomerDTO>> {

		return this.api.post<Array<CustomerDTO>>("Customer/searchCustomerList", customerDTO, false );
	}

	searchCustomerMap(customerDTO:CustomerDTO) : Observable<Map<number, CustomerDTO>> {

		return this.api.post<Map<number, CustomerDTO>>("Customer/searchCustomerMap", customerDTO, false );
	}

	addCustomer(customerDTO:CustomerDTO) : Observable<CustomerDTO> {

		return this.api.post<CustomerDTO>("Customer/addCustomer", customerDTO, false );
	}

	addCustomerList(customerList:Array<CustomerDTO>) : Observable<Array<CustomerDTO>> {

		return this.api.post<Array<CustomerDTO>>("Customer/addCustomerList", customerList, false );
	}

	updateCustomer(customerDTO:CustomerDTO) : Observable<CustomerDTO> {

		return this.api.post<CustomerDTO>("Customer/updateCustomer", customerDTO, false );
	}

	updateCustomerList(customerList:Array<CustomerDTO>) : Observable<Array<CustomerDTO>> {

		return this.api.post<Array<CustomerDTO>>("Customer/updateCustomerList", customerList, false );
	}

	saveCustomer(customerDTO:CustomerDTO) : Observable<CustomerDTO> {

		return this.api.post<CustomerDTO>("Customer/saveCustomer", customerDTO, false );
	}

	saveCustomerList(customerList:Array<CustomerDTO>) : Observable<Array<CustomerDTO>> {

		return this.api.post<Array<CustomerDTO>>("Customer/saveCustomerList", customerList, false );
	}

	activateCustomerById(id:number) : Observable<number>{

		return this.api.post<number>("Customer/activateCustomerById", id, false );
	}

	activateCustomer(customerDTO:CustomerDTO) : Observable<CustomerDTO> {

		return this.api.post<CustomerDTO>("Customer/activateCustomer", customerDTO, false );
	}

	activateCustomerList(customerList:Array<CustomerDTO>) : Observable<Array<CustomerDTO>> {

		return this.api.post<Array<CustomerDTO>>("Customer/activateCustomerList", customerList, false );
	}

	deactivateCustomerById(id:number) : Observable<number> {

		return this.api.post<number>("Customer/deactivateCustomerById", id, false );
	}

	deactivateCustomer(customerDTO:CustomerDTO) : Observable<CustomerDTO> {

		return this.api.post<CustomerDTO>("Customer/deactivateCustomer", customerDTO, false );
	}

	deactivateCustomerList(customerList:Array<CustomerDTO>) : Observable<Array<CustomerDTO>> {

		return this.api.post<Array<CustomerDTO>>("Customer/deactivateCustomerList", customerList, false );
	}
}