import { APP_INITIALIZER, NgModule, Optional, PLATFORM_ID } from '@angular/core';
import { BrowserModule, BrowserTransferStateModule } from '@angular/platform-browser';
import { RouteReuseStrategy } from '@angular/router';
import { IonicModule, IonicRouteStrategy } from '@ionic/angular';
import { ServiceWorkerModule } from '@angular/service-worker';
import { RESPONSE } from '@nguniversal/express-engine/tokens';
import { isPlatformServer } from '@angular/common';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { ComponentsModule } from './components/components.module';
import { environment } from '../environments/environment';
import { ReactiveFormsModule } from '@angular/forms';

import { HttpClientModule, HttpClient } from '@angular/common/http';
import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';

import { IonicStorageModule } from '@ionic/storage';
import { RestaurantService } from './services/restaurant.service';
import { Api } from './base-service/api.service';
import { BaseCustomerCuisinesService } from './base-service/base-customer-cuisines.service';
import { BaseCustomerFriendsService } from './base-service/base-customer-friends.service';
import { BaseCustomerOfferService } from './base-service/base-customer-offer.service';
import { BaseCustomerWishlistService } from './base-service/base-customer-wishlist.service';
import { CustomerService } from './services/customer.service';
import { BaseTcodeOccupationService } from './base-service/base-tcode-occupation.service';
import { BaseDeviceService } from './base-service/base-device.service';
import { BaseLoginHistService } from './base-service/base-login-hist.service';
import { BaseParameterService } from './base-service/base-parameter.service';
import { BaseRestaurantCuisinesService } from './base-service/base-restaurant-cuisines.service';
import { BaseRestaurantRateService } from './base-service/base-restaurant-rate.service';
import { BaseRestaurantDesignService } from './base-service/base-restaurant-design.service';
import { BaseRoleService } from './base-service/base-role.service';
import { BaseSupplierService } from './base-service/base-supplier.service';
import { BaseTcodeCityService } from './base-service/base-tcode-city.service';
import { BaseTcodeCountryService } from './base-service/base-tcode-country.service';
import { BaseTcodeCuisineService } from './base-service/base-tcode-cuisine.service';
import { BaseTcodeRestDesignService } from './base-service/base-tcode-rest-design.service';
import { BaseTcodeRestDestinationService } from './base-service/base-tcode-rest-destination.service';
import { BaseTcodeRestEnvironmentService } from './base-service/base-tcode-rest-environment.service';
import { BaseTcodeStatusService } from './base-service/base-tcode-status.service';
import { BaseTcodeSupplierTypeService } from './base-service/base-tcode-supplier-type.service';
import { BaseTcodeSpecialityService } from './base-service/base-tcode-speciality.service';
import { BaseUserService } from './base-service/base-user.service';
import { DataService } from './base-service/data.service';
import { LoadingService } from './services/loading.service';
import { OfflineStorage } from './services/storage.service';
import { Alert } from './services/alert.service';
import { BaseOfferService } from './base-service/base-offer.service';
import { CustomerOfferService } from './services/customer-offer.service';
import { SuperTabsModule } from '@ionic-super-tabs/angular';
import { BaseTcodeRejectionReasonService } from './base-service/base-tcode-rejection-reason.service';
import { LoginService } from './services/login.service';
import { DealsService } from './deals/deals.service';

import { NativeGeocoder } from '@ionic-native/native-geocoder/ngx';
import { LaunchNavigator } from '@ionic-native/launch-navigator/ngx';
import { BaseRestReturnCustomerService } from './base-service/base-rest-return-customer.service';
import { ShareService } from './base-service/share.service';


export function createTranslateLoader(http: HttpClient) {
  return new TranslateHttpLoader(http, './assets/i18n/', '.json');
}

@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserModule.withServerTransition({ appId: 'serverApp' }),
    BrowserTransferStateModule,
    IonicStorageModule.forRoot(),
    IonicModule.forRoot(),
    ReactiveFormsModule,
    AppRoutingModule,
    HttpClientModule,
    ComponentsModule,
    ServiceWorkerModule.register('/ngsw-worker.js', { enabled: environment.production }),
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: (createTranslateLoader),
        deps: [HttpClient]
      }
    }),
    SuperTabsModule.forRoot()
  ],
  providers: [
    
    { provide: RouteReuseStrategy, useClass: IonicRouteStrategy },
    	//  Custome Fados Services Providers
                Api,
                ShareService,
                Alert,
                BaseCustomerCuisinesService,   
                BaseCustomerFriendsService,    
                BaseCustomerOfferService,    
                BaseCustomerWishlistService,   
                CustomerService,  
                BaseTcodeOccupationService, 
                BaseDeviceService,    
                BaseLoginHistService,  
                BaseOfferService,
                CustomerOfferService,   
                BaseParameterService,   
                BaseRestaurantCuisinesService,  
                BaseRestaurantRateService,   
                BaseRestaurantDesignService,
                RestaurantService,   
                BaseRoleService,    
                BaseSupplierService,    
                BaseTcodeCityService,    
                BaseTcodeCountryService,   
                BaseTcodeCuisineService,  
                BaseTcodeRestDesignService,    
                BaseTcodeRestDestinationService,   
                BaseTcodeRestEnvironmentService,   
                BaseTcodeStatusService,    
                BaseTcodeSupplierTypeService,   
                BaseTcodeSpecialityService,
                BaseTcodeRejectionReasonService,
                BaseUserService,
                BaseRestReturnCustomerService,
                DataService,
                LoadingService,
                LoginService,
                OfflineStorage,
                DealsService, // This is for offer listing service
                NativeGeocoder,
                LaunchNavigator,              

    {
      provide: APP_INITIALIZER,
      useFactory: (platformId: object, response: any) => {
        return () => {
          // In the server.ts we added a custom response header with information about the device requesting the app
          if (isPlatformServer(platformId)) {
            if (response && response !== null) {
              // Get custom header from the response sent from the server.ts
              const mobileDeviceHeader = response.get('mobile-device');

              // Set Ionic config mode?
            }
          }
        };
      },
      deps: [PLATFORM_ID, [new Optional(), RESPONSE]],
      multi: true
    }
  ],
  bootstrap: [AppComponent]
})
export class AppModule {}
