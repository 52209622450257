import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {Api} from './api.service';
import {CustomerFriendsDTO} from '../dto/customer-friends-dto';
import {CustomerDTO} from '../dto/customer-dto';

@Injectable()
export class BaseCustomerFriendsService {

	constructor(public api:Api) {

	}

	getCustomerFriendsById(id:number) : Observable<CustomerFriendsDTO> {

		return this.api.post<CustomerFriendsDTO>("CustomerFriends/getCustomerFriendsById", id , false );
	}

	getCustomerFriends(customerFriendsDTO:CustomerFriendsDTO) : Observable<CustomerFriendsDTO> {

		return this.api.post<CustomerFriendsDTO>("CustomerFriends/getCustomerFriends", customerFriendsDTO, false );
	}

	getCustomerFriendssByCustomerId(customerDTO:CustomerDTO) : Observable<CustomerDTO> {

		return this.api.post<CustomerDTO>("CustomerFriends/getCustomerFriendssByCustomerId", customerDTO, false );
	}

	getCustomerFriendsListByCustomerId(customerId:number) : Observable<Array<CustomerFriendsDTO>> {

		return this.api.post<Array<CustomerFriendsDTO>>("CustomerFriends/getCustomerFriendsListByCustomerId", customerId, false );
	}

	getCustomerFriendsMapByCustomerId(customerId:number) : Observable<Map<number, CustomerFriendsDTO>>  {

		return this.api.post<Map<number, CustomerFriendsDTO>>("CustomerFriends/getCustomerFriendsMapByCustomerId", customerId, false );
	}

	getCustomerFriendsList() : Observable<CustomerFriendsDTO[]> {

		return this.api.get<CustomerFriendsDTO[]>("CustomerFriends/getCustomerFriendsList", false );
	}

	getCustomerFriendsMap() : Observable<Map<number, CustomerFriendsDTO>> {

		return this.api.get<Map<number, CustomerFriendsDTO>>("CustomerFriends/getCustomerFriendsMap", false );
	}

	searchCustomerFriendsList(customerFriendsDTO:CustomerFriendsDTO) : Observable<Array<CustomerFriendsDTO>> {

		return this.api.post<Array<CustomerFriendsDTO>>("CustomerFriends/searchCustomerFriendsList", customerFriendsDTO, false );
	}

	searchCustomerFriendsMap(customerFriendsDTO:CustomerFriendsDTO) : Observable<Map<number, CustomerFriendsDTO>> {

		return this.api.post<Map<number, CustomerFriendsDTO>>("CustomerFriends/searchCustomerFriendsMap", customerFriendsDTO, false );
	}

	addCustomerFriends(customerFriendsDTO:CustomerFriendsDTO) : Observable<CustomerFriendsDTO> {

		return this.api.post<CustomerFriendsDTO>("CustomerFriends/addCustomerFriends", customerFriendsDTO, true );
	}

	addCustomerFriendsList(customerFriendsList:Array<CustomerFriendsDTO>) : Observable<Array<CustomerFriendsDTO>> {

		return this.api.post<Array<CustomerFriendsDTO>>("CustomerFriends/addCustomerFriendsList", customerFriendsList, true );
	}

	updateCustomerFriends(customerFriendsDTO:CustomerFriendsDTO) : Observable<CustomerFriendsDTO> {

		return this.api.post<CustomerFriendsDTO>("CustomerFriends/updateCustomerFriends", customerFriendsDTO, true );
	}

	updateCustomerFriendsList(customerFriendsList:Array<CustomerFriendsDTO>) : Observable<Array<CustomerFriendsDTO>> {

		return this.api.post<Array<CustomerFriendsDTO>>("CustomerFriends/updateCustomerFriendsList", customerFriendsList, true );
	}

	saveCustomerFriends(customerFriendsDTO:CustomerFriendsDTO) : Observable<CustomerFriendsDTO> {

		return this.api.post<CustomerFriendsDTO>("CustomerFriends/saveCustomerFriends", customerFriendsDTO, true );
	}

	saveCustomerFriendsList(customerFriendsList:Array<CustomerFriendsDTO>) : Observable<Array<CustomerFriendsDTO>> {

		return this.api.post<Array<CustomerFriendsDTO>>("CustomerFriends/saveCustomerFriendsList", customerFriendsList, true );
	}

	activateCustomerFriendsById(id:number) : Observable<number>{

		return this.api.post<number>("CustomerFriends/activateCustomerFriendsById", id, true );
	}

	activateCustomerFriends(customerFriendsDTO:CustomerFriendsDTO) : Observable<CustomerFriendsDTO> {

		return this.api.post<CustomerFriendsDTO>("CustomerFriends/activateCustomerFriends", customerFriendsDTO, true );
	}

	activateCustomerFriendsList(customerFriendsList:Array<CustomerFriendsDTO>) : Observable<Array<CustomerFriendsDTO>> {

		return this.api.post<Array<CustomerFriendsDTO>>("CustomerFriends/activateCustomerFriendsList", customerFriendsList, true );
	}

	deactivateCustomerFriendsById(id:number) : Observable<number> {

		return this.api.post<number>("CustomerFriends/deactivateCustomerFriendsById", id, true );
	}

	deactivateCustomerFriends(customerFriendsDTO:CustomerFriendsDTO) : Observable<CustomerFriendsDTO> {

		return this.api.post<CustomerFriendsDTO>("CustomerFriends/deactivateCustomerFriends", customerFriendsDTO, true );
	}

	deactivateCustomerFriendsList(customerFriendsList:Array<CustomerFriendsDTO>) : Observable<Array<CustomerFriendsDTO>> {

		return this.api.post<Array<CustomerFriendsDTO>>("CustomerFriends/deactivateCustomerFriendsList", customerFriendsList, true );
	}
}