import { Injectable } from '@angular/core';
import { LoadingController } from '@ionic/angular';

@Injectable()
export class LoadingService {

  loadingElement:any
	constructor(public loadingController: LoadingController) {

	}

  async presentLoading() {
    this.loadingElement = await this.loadingController.create({
      cssClass: 'transparent-loading-class', // check CSS in variables.scss + also refer to other variables    https://ionicframework.com/docs/api/loading#css-custom-properties
      message: '',//Please wait...
      spinner: 'circular', // bubbles" | "circles" | "circular" | "crescent" | "dots" | "lines" | "lines-small" | null | undefined
      duration: 2000
    });
    await this.loadingElement.present();

    const { role, data } = await this.loadingElement.onDidDismiss();
  }

  // async presentLoadingWithOptions() {
  //   const loading = await this.loadingController.create({
  //     cssClass: 'my-custom-class',
  //     spinner: null,
  //     duration: 5000,
  //     message: 'Click the backdrop to dismiss early...',
  //     translucent: true,
  //     //cssClass: 'custom-class custom-loading',
  //     backdropDismiss: true
  //   });
  //   await loading.present();

  //   const { role, data } = await loading.onDidDismiss();
  //   console.log('Loading dismissed with role:', role);
  // }

  async dismiss(){

    if (this.loadingElement) {
      await this.loadingElement.dismiss();
    }
}

}