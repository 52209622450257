import { Injectable } from '@angular/core';
// import { HttpClient } from '@angular/common/http';

import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import * as dayjs from 'dayjs';

import { DataStore } from '../shell/data-store';
import { DealsListingModel } from './listing/deals-listing.model';
import { DealsDetailsModel } from './details/deals-details.model';
import { CustomerService } from '../services/customer.service';
import { CustomerOfferDTO } from '../dto/customer-offer-dto';
import { TcodeCityDTO } from '../dto/tcode-city-dto';

@Injectable()
export class DealsService {
  private listingDataStore: DataStore<DealsListingModel>;
  // private detailsDataStore: DataStore<DealsDetailsModel>;
  customerID:number ;
  currentCityDTO:TcodeCityDTO;
  customerOffersList:CustomerOfferDTO[]
  constructor(private customerService:CustomerService) {
    
   }

  // setCustomer(customerID) {
  //   this.customerID = customerID;
  // }
  // getCustomer() {
  //   return  this.customerID;
  // }

  setCurrentCityDTO(currentCityDTO) {
    this.currentCityDTO = currentCityDTO;
  }

  getCurrentCityDTO() {
    return  this.currentCityDTO;
  }

  // setCustomerOffersList(customerOffersList) {
  //   this.customerOffersList = customerOffersList;
  // }
  // getCustomerOffersList() {
  //   return  this.customerOffersList;
  // }

  // get relativeDates(): Array<string> {
  //   return [
  //     dayjs().add(1, 'day').add(0, 'hour').add(10, 'minute').add(10, 'second').format('MM/DD/YYYY HH:mm:ss') as string
  //   ];
  // }



  public calculateDistance(dealItem:CustomerOfferDTO){
    return 0;
    // let lat1:number=0,lat2:number=0,long1:number=0,long2:number =0;
    // lat1 = +dealItem.offerDTO.restaurantDTO.locationLatitude;
    // long1= + dealItem.offerDTO.restaurantDTO.locationLongitude;
    
    // lat2 = 26.099179099999997;
    // long2 = 50.485103599999995;
    // if(lat1>0 && lat2>0 && long1 >0 && long2>0){
       

    //     let p = 0.017453292519943295;    // Math.PI / 180
    //     let c = Math.cos;
    //     let a = 0.5 - c((lat1-lat2) * p) / 2 + c(lat2 * p) *c((lat1) * p) * (1 - c(((long1- long2) * p))) / 2;
    //     let dis = (12742 * Math.asin(Math.sqrt(a))); // 2 * R; R = 6371 km
    //     return dis.toFixed(2);
    // }else{
    //   return 0;
    // }
  }

  public getListingDataSource(customerOfferIdList:any): Observable<DealsListingModel> {
    //return this.customerService.getPendingCustomerOfferListByCustomerId(customerID)   
    return this.customerService.getGeneratedCustomerOfferList(customerOfferIdList)     
      .pipe(
      map(
        (data: DealsListingModel) => {

          // Using rest operator to divide the data (see: https://dev.to/napoleon039/how-to-use-the-spread-and-rest-operator-4jbb)
          const {items, ...otherData} = data;
         // dayjs("12-25-1995", "DD-MM-YYYY")
    
          const itemsWithDistance = items.map((dealItem, index) => {
                return {...dealItem, distance: this.calculateDistance(dealItem) }; //Currently no use of calculating distance here
          });
         

          // Using spread operator to concat the data (see: https://dev.to/napoleon039/how-to-use-the-spread-and-rest-operator-4jbb)
          const updatedListingData = {...otherData, items: itemsWithDistance};

          // Note: HttpClient cannot know how to instantiate a class for the returned data
          // We need to properly cast types from json data
          const listing = new DealsListingModel();

          // The Object.assign() method copies all enumerable own properties from one or more source objects to a target object.
          // Note: If you have non-enummerable properties, you can try a spread operator instead. listing = {...data};
          // (see: https://scotch.io/bar-talk/copying-objects-in-javascript#toc-using-spread-elements-)
          Object.assign(listing, updatedListingData);
          return listing;
        }
      )
    );

  // });


  // return null;
  }

  public getListingStore(dataSource: Observable<DealsListingModel>): DataStore<DealsListingModel> {
    // Use cache if available
    //if (!this.listingDataStore) { // Removed for Fados listing as we need FRESH data always and no cach required
      // Initialize the model specifying that it is a shell model
      const shellModel: DealsListingModel = new DealsListingModel();
      this.listingDataStore = new DataStore(shellModel);
      // Trigger the loading mechanism (with shell) in the dataStore
      this.listingDataStore.load(dataSource);
   // }
    return this.listingDataStore;
  }

}
