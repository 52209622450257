import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {Api} from './api.service';
import {RoleDTO} from '../dto/role-dto';

@Injectable()
export class BaseRoleService {

	constructor(public api:Api) {

	}

	getRoleById(id:number) : Observable<RoleDTO> {

		return this.api.post<RoleDTO>("Role/getRoleById", id, false );
	}

	getRole(roleDTO:RoleDTO) : Observable<RoleDTO> {

		return this.api.post<RoleDTO>("Role/getRole", roleDTO, false );
	}

	getRoleList() : Observable<RoleDTO[]> {

		return this.api.get<RoleDTO[]>("Role/getRoleList", false );
	}

	getRoleMap() : Observable<Map<number, RoleDTO>> {

		return this.api.get<Map<number, RoleDTO>>("Role/getRoleMap", false );
	}

	searchRoleList(roleDTO:RoleDTO) : Observable<Array<RoleDTO>> {

		return this.api.post<Array<RoleDTO>>("Role/searchRoleList", roleDTO, false );
	}

	searchRoleMap(roleDTO:RoleDTO) : Observable<Map<number, RoleDTO>> {

		return this.api.post<Map<number, RoleDTO>>("Role/searchRoleMap", roleDTO, false );
	}

	addRole(roleDTO:RoleDTO) : Observable<RoleDTO> {

		return this.api.post<RoleDTO>("Role/addRole", roleDTO, false );
	}

	addRoleList(roleList:Array<RoleDTO>) : Observable<Array<RoleDTO>> {

		return this.api.post<Array<RoleDTO>>("Role/addRoleList", roleList, false );
	}

	updateRole(roleDTO:RoleDTO) : Observable<RoleDTO> {

		return this.api.post<RoleDTO>("Role/updateRole", roleDTO, false );
	}

	updateRoleList(roleList:Array<RoleDTO>) : Observable<Array<RoleDTO>> {

		return this.api.post<Array<RoleDTO>>("Role/updateRoleList", roleList, false );
	}

	saveRole(roleDTO:RoleDTO) : Observable<RoleDTO> {

		return this.api.post<RoleDTO>("Role/saveRole", roleDTO, false );
	}

	saveRoleList(roleList:Array<RoleDTO>) : Observable<Array<RoleDTO>> {

		return this.api.post<Array<RoleDTO>>("Role/saveRoleList", roleList, false );
	}

	activateRoleById(id:number) : Observable<number>{

		return this.api.post<number>("Role/activateRoleById", id, false );
	}

	activateRole(roleDTO:RoleDTO) : Observable<RoleDTO> {

		return this.api.post<RoleDTO>("Role/activateRole", roleDTO, false );
	}

	activateRoleList(roleList:Array<RoleDTO>) : Observable<Array<RoleDTO>> {

		return this.api.post<Array<RoleDTO>>("Role/activateRoleList", roleList, false );
	}

	deactivateRoleById(id:number) : Observable<number> {

		return this.api.post<number>("Role/deactivateRoleById", id, false );
	}

	deactivateRole(roleDTO:RoleDTO) : Observable<RoleDTO> {

		return this.api.post<RoleDTO>("Role/deactivateRole", roleDTO, false );
	}

	deactivateRoleList(roleList:Array<RoleDTO>) : Observable<Array<RoleDTO>> {

		return this.api.post<Array<RoleDTO>>("Role/deactivateRoleList", roleList, false );
	}
}