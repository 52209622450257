import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { AlertController } from '@ionic/angular';

import { TranslateService } from '@ngx-translate/core';

@Injectable()
export class Alert { 

    constructor(public alertController: AlertController,
        public router: Router,
        public translateService: TranslateService) {

    }
    /**
     * 
     * 
     * Find more alert examples in below link
     * 
     * 
     * https://ionicframework.com/docs/api/alert
     * 
     * 
     */
    async presentAlert() {
        const alert = await this.alertController.create({
          cssClass: 'my-custom-class',
          header: 'Alert',
          subHeader: 'Subtitle',
          message: 'This is an alert message.',
          buttons: ['OK']
        });
    
        await alert.present();
    }
    
    async message(title:string,subHeader:string ,message:string){

        const alert = await this.alertController.create({
            header: title,
            subHeader: subHeader,
            message : message,
            buttons: ['OK']
        });
        
        await alert.present();
    }

    async presentAlertMultipleButtons() {
        const alert = await this.alertController.create({
          cssClass: 'my-custom-class',
          header: 'Alert',
          subHeader: 'Subtitle',
          message: 'This is an alert message.',
          buttons: ['Cancel', 'Open Modal', 'Delete']
        });
    
        await alert.present();
    }

   

    async show(key:string){

        this.translateService.get(key).subscribe(async(value:string) => {
        
            const alert = await this.alertController.create({
                header: 'Fados',
                subHeader: value,
                buttons: ['OK']
            });
            
            await alert.present();
        });
    }

    async showAndNavigateHome(key:string){

        this.translateService.get(key).subscribe(async(value:string) => {
        
            const alert = await this.alertController.create({
                header: 'Fados',
                subHeader: value,
                buttons: ['OK']
            });
            
            await alert.present();

            alert.onDidDismiss().then((data) => {
                this.router.navigate(["/home"]  );

            });
        });
    }

    async showVariable(key:string, variable:string){

        this.translateService.get(key).subscribe (async (value: string) => {

            const alert = await this.alertController.create({
                header: 'Fados',
                subHeader: value + variable,
                buttons: ['OK']
            });

            await alert.present();
        });
    }

    async confirm(titleKey:string, messageKey:string, handler:any) {

        // this.translateService.get(titleKey).subscribe( async(titleValue:string) => {
            
        //     this.translateService.get(messageKey).subscribe(async(messageValue:string) => {
                
        //         this.translateService.get("SURE").subscribe(async(sure:string) => {

        //             this.translateService.get("CANCEL").subscribe(async(cancel:string) => {
            this.translateService.get([titleKey , messageKey ,"CONFIRM" ,"CANCEL" ]).subscribe (async (transValue: string) => {

                        const alert =  await this.alertController.create({
                            header: transValue[titleKey],
                            message: transValue[messageKey],
                            buttons: [
                              {
                                text: transValue['CONFIRM'],
                                handler: handler
                              },
                              {
                                text: transValue['CANCEL'],
                                role: 'cancel',
                                cssClass: 'secondary',
                              }
                            ]
                          });
                      
                           await alert.present();
        //             });
        //         });
        //     });
         });
    }
}