import {Injectable} from '@angular/core';
import {Api} from '../base-service/api.service';
import { BaseRestaurantService } from '../base-service/base-restaurant.service';
import { RestaurantDTO } from '../dto/restaurant-dto';
import { Observable } from 'rxjs';




@Injectable()
export class RestaurantService extends BaseRestaurantService{

	constructor(public api:Api) {
		super(api );
	}

	addRestaurant(restaurantDTO:RestaurantDTO) : Observable<any> {

		return this.api.post<RestaurantDTO>("RestaurantService/addRestaurant", restaurantDTO, true );
	}

	updateRestaurant(restaurantDTO:RestaurantDTO) : Observable<any> {

		return this.api.post<RestaurantDTO>("RestaurantService/updateRestaurant", restaurantDTO, true );
	}

	getRestaurantSpecificationsLists(restaurantId:number) : Observable<RestaurantDTO> {
		
		return this.api.post<RestaurantDTO>("RestaurantService/getRestaurantSpecificationsLists/"+restaurantId ,restaurantId, false );

	}

	testNotifications(restaurantId:number) : Observable<any> {
		
		return this.api.post<RestaurantDTO>("RestaurantService/testNotifications" ,restaurantId, false );

	}
	
	
}