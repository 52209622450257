import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {Api} from './api.service';
import {DeviceDTO} from '../dto/device-dto';
import {UserDTO} from '../dto/user-dto';

@Injectable()
export class BaseDeviceService {

	constructor(public api:Api) {

	}

	getDeviceById(id:number) : Observable<DeviceDTO> {

		return this.api.post<DeviceDTO>("Device/getDeviceById", id, false );
	}

	getDevice(deviceDTO:DeviceDTO) : Observable<DeviceDTO> {

		return this.api.post<DeviceDTO>("Device/getDevice", deviceDTO, false );
	}

	getDevicesByUserId(userDTO:UserDTO) : Observable<UserDTO> {

		return this.api.post<UserDTO>("Device/getDevicesByUserId", userDTO, false );
	}

	getDeviceListByUserId(userId:number) : Observable<Array<DeviceDTO>> {

		return this.api.post<Array<DeviceDTO>>("Device/getDeviceListByUserId", userId, false );
	}

	getDeviceMapByUserId(userId:number) : Observable<Map<number, DeviceDTO>>  {

		return this.api.post<Map<number, DeviceDTO>>("Device/getDeviceMapByUserId", userId, false );
	}

	getDeviceList() : Observable<DeviceDTO[]> {

		return this.api.get<DeviceDTO[]>("Device/getDeviceList", false );
	}

	getDeviceMap() : Observable<Map<number, DeviceDTO>> {

		return this.api.get<Map<number, DeviceDTO>>("Device/getDeviceMap", false );
	}

	searchDeviceList(deviceDTO:DeviceDTO) : Observable<Array<DeviceDTO>> {

		return this.api.post<Array<DeviceDTO>>("Device/searchDeviceList", deviceDTO, false );
	}

	searchDeviceMap(deviceDTO:DeviceDTO) : Observable<Map<number, DeviceDTO>> {

		return this.api.post<Map<number, DeviceDTO>>("Device/searchDeviceMap", deviceDTO, false );
	}

	addDevice(deviceDTO:DeviceDTO) : Observable<DeviceDTO> {

		return this.api.post<DeviceDTO>("Device/addDevice", deviceDTO, false );
	}

	addDeviceList(deviceList:Array<DeviceDTO>) : Observable<Array<DeviceDTO>> {

		return this.api.post<Array<DeviceDTO>>("Device/addDeviceList", deviceList, false );
	}

	updateDevice(deviceDTO:DeviceDTO) : Observable<DeviceDTO> {

		return this.api.post<DeviceDTO>("Device/updateDevice", deviceDTO, false );
	}

	updateDeviceList(deviceList:Array<DeviceDTO>) : Observable<Array<DeviceDTO>> {

		return this.api.post<Array<DeviceDTO>>("Device/updateDeviceList", deviceList, false );
	}

	saveDevice(deviceDTO:DeviceDTO) : Observable<DeviceDTO> {

		return this.api.post<DeviceDTO>("Device/saveDevice", deviceDTO, false );
	}

	saveDeviceList(deviceList:Array<DeviceDTO>) : Observable<Array<DeviceDTO>> {

		return this.api.post<Array<DeviceDTO>>("Device/saveDeviceList", deviceList, false );
	}

	activateDeviceById(id:number) : Observable<number>{

		return this.api.post<number>("Device/activateDeviceById", id, false );
	}

	activateDevice(deviceDTO:DeviceDTO) : Observable<DeviceDTO> {

		return this.api.post<DeviceDTO>("Device/activateDevice", deviceDTO, false );
	}

	activateDeviceList(deviceList:Array<DeviceDTO>) : Observable<Array<DeviceDTO>> {

		return this.api.post<Array<DeviceDTO>>("Device/activateDeviceList", deviceList, false );
	}

	deactivateDeviceById(id:number) : Observable<number> {

		return this.api.post<number>("Device/deactivateDeviceById", id, false );
	}

	deactivateDevice(deviceDTO:DeviceDTO) : Observable<DeviceDTO> {

		return this.api.post<DeviceDTO>("Device/deactivateDevice", deviceDTO, false );
	}

	deactivateDeviceList(deviceList:Array<DeviceDTO>) : Observable<Array<DeviceDTO>> {

		return this.api.post<Array<DeviceDTO>>("Device/deactivateDeviceList", deviceList, false );
	}
}