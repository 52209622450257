import { Injectable } from '@angular/core';
import { CustomerOfferDTO } from '../dto/customer-offer-dto';
import { Plugins } from '@capacitor/core';
import { TranslateService } from '@ngx-translate/core';

const { Share } = Plugins;


@Injectable()
export class ShareService {

    constructor( public translateService: TranslateService) {

    }


    async shareOffer(customerOfferDTO:CustomerOfferDTO){

        this.translateService.get(["SHARE_CONFIRM_TEXT","SHARE_DOWNLOAD_APP_TEXT" ,"SHARE_WITH_FRIENDS"]).subscribe (async (transValue: string) => {
           

            let sharingText:string =  transValue['SHARE_CONFIRM_TEXT']+"\n\n"+ customerOfferDTO.id +"\n\n"+   transValue['SHARE_DOWNLOAD_APP_TEXT']  ;
            let shareRet = await Share.share({
                title: transValue['SHARE_CONFIRM_TEXT'],
                text: sharingText,
                url: 'https://www.fadosapp.com/',
                dialogTitle: transValue['SHARE_WITH_FRIENDS']
        });

        
        });
    }

}