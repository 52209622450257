import * as dayjs from 'dayjs';

import { ShellModel } from '../../shell/data-store';
import { CustomerOfferDTO } from '../../dto/customer-offer-dto';
import { OfferDTO } from '../../dto/offer-dto';

// export class DealsItemModel {
//   // slug: string;
//   // logo: string;
//   // name: string;
//   // code: string;
//   // description: string;
//   // Default mock value
//   // expirationDate = '12/01/2018';
//   // expirationDate: string = dayjs().add(5, 'day').format('MM/DD/YYYY HH:mm:ss') as string;
// }

export class DealsListingModel extends ShellModel {
  items: Array<CustomerOfferDTO> = [
    new CustomerOfferDTO(),
    new CustomerOfferDTO(),
    new CustomerOfferDTO(),
  ];

  constructor() {
    super();
  }
}
